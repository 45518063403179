.social_icons, .share_icons {
    li {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 15px 0;
        // vars
        background-color: $mainColor;
        // mixin
        @include prefix(transition, background-color .1s linear);
        a {
            height: 100%;
            flex-grow: 1;
        }
        &:hover {
            background-color: $secondColor;
        }
    }
}