.app_popup_sharing {
    display: none !important;
    padding: 85px 12px;
    @include overlay(40%);
    @include prefix(transition, opacity .2s linear);
    &.show {
        display: flex !important;
    }
    &.animation {
        .wrap_content {
            @include prefix(transform, translate(0px, 0px));
        }
    }
    .wrap_content {
        width: 100%;
        max-width: 600px;
        // vars
        background-color: $white;
        // mixin
        @include prefix(transform, translate(0px, 500px));
        @include prefix(transition, transform .1s linear);        
        .wrap_top {
            h4 {
                line-height: normal;                
            }
        }
        .wrap_copy_url {
            width: 100%;
            padding: 10px;
            max-width: 390px;
            padding-inline-start: 44px;
            border: 1px solid rgba($parag, 50%);
            .wrap_icon_copy {
                top: -1px;
                right: -1px;
                bottom: -1px;
                padding: 5px 10px;
                // vars
                background-color: $mainColor;
                // mixin
                @include prefix(border-radius, 0 8px 8px 0);
            }
            .copied {
                top: -1px;
                left: -1px;
                bottom: -1px;
                display: none;
                position: absolute;
                align-items: center;
                justify-content: center;
                width: calc(100% - 42px);
                @include prefix(border-radius, 8px 0 0 8px)
            }
        }
        ul {
            width: 100%;
            max-width: 250px;
            margin: 36px auto 5px auto;
            li {
                &:hover a {
                    background-color: rgba($blue_700, 12%);
                }
                a {
                   width: 48px;
                   height: 48px;
                   display: block;
                    // mixin
                   @include prefix(border-radius, 9999px);
                   @include prefix(transition, background-color .1s linear)
               }
            }
        }
    }
}