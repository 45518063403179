.app_card {
  @include prefix(box-shadow, 0px 5px 8px 8px rgba($black, 4%));
  &.full_details {
    .wrap_bottom {
      margin-top: 0px !important;
    }
  }
  .wrap_top {
    .panner {
      width: 100%;
      height: 100%;
      min-height: 270px;
      max-height: 365px;
      object-fit: cover;
      border-radius: 100px 0;
    }
    .sharing {
      right: -159px;
      height: 45px;
      top: 20px;
      padding: 5px 15px 5px 15px;
      // vars
      background-color: rgba($white, 75%);
      // mixin
      @include prefix(border-radius, 5px 0 0 5px);
      @include prefix(transition, right 0.2s linear);
      &.show {
        right: 0px;
        svg {
          width: 0px;
          // var
        }
      }
      svg {
        // var
        fill: $mainColor;
        // mixn
        @include prefix(transition, width 0.2s linear);
      }
    }
    .cost {
      left: 14px;
      bottom: 14px;
      width: calc(100% - 50px);
      background-color: rgba($white, 75%);
      border-radius: 80px 0;
      padding: 40px;
      @include prefix(backdrop-filter, blur(2px));
      .amount {
        span {
          color: $writing;
          font-family: $mainFont;
          &:first-of-type {
            font-size: 14px;
            font-weight: 300;
          }
          &:last-of-type {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      .product_progress {
        z-index: 1;
        height: 20px;
        font-size: 10px;
        font-weight: 500;
        line-height: normal;
        background-color: rgba(#729aa8, 30%);
        // vars mixin
        color: $white;
        font-family: $mainFont;
        @include prefix(border-radius, 4px);
        .progress_status {
          top: 0;
          right: 0;
          bottom: 0;
          width: 10%;
          z-index: -1;
          // var
          background: $mainColor;
          // mixin
          @include prefix(border-radius, 4px);
        }
        .span {
          vertical-align: middle;
        }
      }
    }
  }
  .wrap_mid {
    & > * {
      font-family: $mainFont;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      // vars
      color: $writing;
      span {
        overflow: hidden;
        display: -webkit-box;
        @include prefix(line-clamp, 1);
        @include prefix(box-orient, vertical);
        &:first-of-type {
          line-height: normal;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // vars
      color: $parag;
      // @include prefix(line-clamp, 2);
      // @include prefix(box-orient, vertical);
    }
  }
  .wrap_bottom {
    margin-top: 30px;
    .cates {
      li {
        &.break_v_line {
          width: 1px;
          height: 14px;
          display: inline-block;
          // vertical-align: bottom;
          background-color: $parag_2;
        }
        span {
          font-weight: 500;
          // vars
          color: $parag;
          font-family: $mainFont;
        }
      }
    }
    .details {
      li {
        width: calc(50% - 16px);
      }
    }
    .wrap_input {
      .wrap_pay {
        left: 0;
        top: 50%;
        z-index: 0;
        height: 100%;
        padding: 0 12px;
        // vars & mixin
        background-color: $mainColor;
        @include prefix(border-radius, 8px 0 0 8px);
        @include prefix(transform, translate(0, -50%));
        @include prefix(transition, background-color 0.1s linear);
        span {
          color: $white
        }
        svg path {
          fill: $white;
        }
        span, svg {
          @include prefix(transition, all 0.1s linear);
        }
        &:hover {
          background-color: $secondColor;
        }
      }
    }
  }
  .wrap_icon {
    .heart_icon {
      border: 0.5px solid $mainColor;
    }
    .share_icon {
      background-color: rgba($parag, 15%);
    }
  }
}
