.account_donations {
    .counters {
        width: 100%;
        max-width: 405px;
        .wrap_img {
            width: 56px;
            height: 56px;
            background-color: $green_50;         
            // mixin       
            @include prefix(border-radius, 9999px)
        }
    }
    table {
        border-spacing: 0;
        border-collapse: separate;
        thead, tbody {
            width: 100%;
            // display: table;
          }                        
        thead {
            // margin-bottom: 8px;
            th {
                font-size: 18px;
                font-weight: 0400;
                text-align: center;
                padding: 18px 26px;
                border-bottom-width: 0px;
                background: rgba(#C1C1C1, 20%);               
                // vars
                color: $blue_950;
                font-family: $secondFont;
                &:first-of-type {
                    @include prefix(border-radius, 0px 8px 8px 0px);
                }
                &:last-of-type {
                    @include prefix(border-radius, 8px 0 0px 8px);
                }
            }
        }
        tbody {
            margin-top: 8px; /* Adjust the margin-bottom as needed */                
            tr {
                &:first-of-type {
                    th, td {
                        border-top: none !important;
                    }
                }
            }
            th, td {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                padding: 24px 26px;
                border: 1px solid #EDEDED;
                // vars
                color: $parag;
                font-family: $secondFont;
                &:first-of-type {
                    border-right-width: 0px;
                }
                &:last-of-type {
                    border-left-width: 0px;
                }
            }
        }
    }
}