.fast_donate {
    right: 0;
    top: 200px;
    width: 100%;
    z-index: 50;
    position: fixed;
    max-width: 160px;
    // mixin
    @include prefix(border-radius, 12px 0 0 12px);
    @include prefix(transition, all .1s ease-in-out);
    &.collapsed {
        max-width: 60px;
        span {
            display: none;
        }
    }
    &.show {
        svg {
            &:first-of-type {
                display: none !important;
            }
            &:last-of-type {
                display: block !important;
            }                
        }
    }
    &:hover {
        color: $mainColor !important;
        background-color: $white !important;
        svg, svg circle {
            stroke: $mainColor;
        }
    }
    span {
        white-space: nowrap;
    }
    svg {
        display: none;
        &:first-of-type {
            display: block;
        }
    }
}