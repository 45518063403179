.carousel_panner {
    margin: 48px auto;
    overflow: hidden;
    border-radius: 100px 0;
    .carousel-indicators {
        button {
            width: 15px;
            height: 15px;
            // opacity: 1 !important;
            border: none !important;
            background-clip: unset !important;
            @include prefix(border-radius, 999px);
            @include prefix(transition, width .3s ease-in-out);
            &.active {
                width: 40px;
                // vars
                background-color: $mainColor;
            }
        }
    }
    .carousel-item {
        img {
            height: 267px;
            max-width: 100%;
            object-fit: cover;
            // border-radius: 100px 0;
        }
        .carousel-caption {
            inset: 0;
            background-color: rgba($black, 40%);
            h1 {
                padding: 0 12px;
                font-size: 48px;
                font-weight: 700;
                line-height: 55px;
                color: #f7f8f8;
                // vars
                font-family: $mainFont;
            }
        }
    }
}