//
.my_container {
    width: 100%;
    margin: 0 auto;
    max-width: 1400px;
    padding-inline-end: 12px;
    padding-inline-start: 12px;    
}

body {
    overflow: hidden;
    font-family: $mainFont;
}

.pointer {
    cursor: pointer;
}

.hover_main_bg {
    &:hover {
        // vars
        color: $white;
        background: $green_900;
    }
    // mixin
    @include prefix(transition, background .2s ease-in-out);
}

.app_card_v1 {
    padding: 24px;
    @include prefix(border-radius, 12px);
    @include prefix(box-shadow, 0px 5px 8px 8px rgba($black, 4%));
}

.app_card_v2 {
    padding: 16px;
    @include prefix(border-radius, 12px);
    @include prefix(box-shadow, 2px 10px 20px 15px rgba($black, 4%));
}

.text_under_none {
    text-decoration: none;
}

.dir_rtl {
    direction: rtl !important;
}

.dir_ltr {
    direction: ltr !important;
}

.obj_img_cover {
    object-fit: cover;
}

.border_1 {
    border: 1px solid $parag;
}

.border_0_5 {
    border: 0.5px solid $parag;
}

.main_color {
    color: $mainColor !important;
}

.second_color {
    color: $secondColor !important;
}

.heading_color {
    color: $headingColor !important;
}
.header_color_2 {
    color:  $headingColor_2 !important;
}
.header_color_3 {
    color:  $headingColor_3 !important;
}
.header_color_4 {
    color:  $headingColor_4 !important;
}
.header_color_5 {
    color:  $headingColor_5 !important;
}
.header_color_6 {
    color:  $headingColor_6 !important;
}


.parag {
    color: $parag;
}

.writing {
    color: $writing;
}

.parag_2 {
    color: $parag_2;
}

.green_950 {
    color: $green_950;
}

.green_900 {
    color: $green_900;
}

.green_800 {
    color: $green_800;
}

.green_700 {
    color: $green_700;
}

.green_600 {
    color: $green_600;
}

.green_400 {
    color: $green_400;
}

.green_300 {
    color: $green_300;
}

.green_200 {
    color: $green_200;
}

.green_100 {
    color: $green_100;
}

.green_50 {
    color: $green_50;
}

.blue_950 {
    color: $blue_950;
}

.blue_900 {
    color: $blue_900;
}

.blue_800 {
    color: $blue_800;
}

.main_bg {
    background-color: $mainColor !important;
}

.second_bg {
    background-color: $secondColor !important;
}

.parag_bg {
    background-color: $parag;
}

.parag_2_bg {
    background-color: $parag_2;
}

.green_bg_50 {
    background-color: $green_50;
}

.blue_bg_900 {
    background-color: $blue_900;
}

.blue_bg_800 {
    background-color: $blue_800;
}

.gradient {
    background: linear-gradient(92deg, #38BA76 -28.2%, #7AC255 147.15%);
}

// radius
.border_radius_xs {
    border-radius: 20px 0 !important;
}
.border_radius_xs_reverse {
    border-radius: 0px 20px !important;
}

.border_radius_sm {
    border-radius: 50px 0 !important;
}
.border_radius_sm_reverse {
    border-radius: 0px 50px !important;
}

.border_radius_md {
    border-radius: 100px 0 !important;
}
.border_radius_md_reverse {
    border-radius: 0px 100px !important;
}

.border_radius_lg {
    border-radius: 150px 0 !important;
}
.border_radius_lg_reverse {
    border-radius: 0px 150px !important;
}

//cutome_scroll
.custom_scroll {
    padding-inline-end: 3px !important;
    &.md {
        padding-inline-end: 5px !important;
        &::-webkit-scrollbar {
            width: 10px;
        }        
    }
    &::-webkit-scrollbar {
        margin:  15px 0 ;
    }
       
    &::-webkit-scrollbar-track {
        margin:  15px 0 ;
        border-radius: 100px;
        background-color: rgb(219, 217, 217);
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: $blue_900,
    }     
}


// margin & padding
@for $i from 1 through 12 {
    @if $i == 1 {
        // margin
        .margin_full {
            margin: $i * 4 + px;;
        }
        .margin_start {
            margin-inline-start: $i * 4 + px;
        }
        .margin_end {
            margin-inline-end: $i * 4 + px;
        }
        .margin_top {
            margin-block-start: $i * 4 + px;
        }
        .margin_bottom {
            margin-block-end: $i * 4 + px;
        }
        .margin_x {
            margin-inline-end: $i * 4 + px;
            margin-inline-start: $i * 4 + px;
        }  
        .margin_y {
            margin-block-end: $i * 4 + px;
            margin-block-start: $i * 4 + px;
        }

        // padding
        .padding_full {
            padding: $i * 4 + px;;
        }
        .padding_start {
            padding-inline-start: $i * 4 + px;
        }
        .padding_end {
            padding-inline-end: $i * 4 + px;
        }
        .padding_top {
            padding-block-start: $i * 4 + px;
        }
        .padding_bottom {
            padding-block-end: $i * 4 + px;
        }
        .padding_x {
            padding-inline-end: $i * 4 + px;
            padding-inline-start: $i * 4 + px;
        }  
        .padding_y {
            padding-block-end: $i * 4 + px;
            padding-block-start: $i * 4 + px;
        }        

        // border-radius
        .border_radius {
            @include prefix(border-radius, $i * 4 + px);
        }
    }
    @else {

        // margin
        .margin_full_#{$i} {
            margin: $i * 4 + px;;
        }        
        .margin_start_#{$i} {
            margin-inline-start: $i * 4 + px;
        }
        .margin_end_#{$i} {
            margin-inline-end: $i * 4 + px;
        }
        .margin_top_#{$i} {
            margin-block-start: $i * 4 + px;
        }
        .margin_bottom_#{$i} {
            margin-block-end: $i * 4 + px;
        }
        .margin_x_#{$i} {
            margin-inline-end: $i * 4 + px;
            margin-inline-start: $i * 4 + px;
        }  
        .margin_y_#{$i} {
            margin-block-end: $i * 4 + px;
            margin-block-start: $i * 4 + px;
        }        

        // padding
        .padding_full_#{$i} {
            padding: $i * 4 + px;;
        }          
        .padding_start_#{$i} {
            padding-inline-start: $i * 4 + px;
        }
        .padding_end_#{$i} {
            padding-inline-end: $i * 4 + px;
        }
        .padding_top_#{$i} {
            padding-block-start: $i * 4 + px;
        }
        .padding_bottom_#{$i} {
            padding-block-end: $i * 4 + px;
        }
        .padding_x_#{$i} {
            padding-inline-end: $i * 4 + px;
            padding-inline-start: $i * 4 + px;
        }  
        .padding_y_#{$i} {
            padding-block-end: $i * 4 + px;
            padding-block-start: $i * 4 + px;
        }

        // radius
        .border_radius_#{$i} {
            @include prefix(border-radius, $i * 4 + px);
        }
    }
}

// icons Sizes
$def_size: 12;

@for $size from 1 through 12 {
    .icon_#{$def_size} {
        width: $def_size + px !important;
        height: $def_size + px !important;
    }

    .font_#{$def_size} {
        font-size: $def_size + px !important;
    }    
    $def_size: $def_size + 4;
} 

.font_14 {
    font-size: 14px !important;
}

// main font
.main_font_100 {
    font-weight: 100 !important;
    font-family: $mainFont !important;
}

.main_font_300 {
    font-weight: 300 !important;
    font-family: $mainFont !important;
}
.main_font_500 {
    font-weight: 500 !important;
    font-family: $mainFont !important;
}
.main_font_700 {
    font-weight: 700 !important;
    font-family: $mainFont !important;
}
.main_font_800 {
    font-weight: 800 !important;
    font-family: $mainFont !important;
}
.main_font_900 {
    font-weight: 900 !important;
    font-family: $mainFont !important;
}

// second font
.second_font_100 {
    font-weight: 100 !important;
    font-family: $secondFont !important;
}
.second_font_200 {
    font-weight: 200 !important;
    font-family: $secondFont !important;
}
.second_font_300 {
    font-weight: 300 !important;
    font-family: $secondFont !important;
}
.second_font_400 {
    font-weight: 400 !important;
    font-family: $secondFont !important;
}
.second_font_500 {
    font-weight: 500 !important;
    font-family: $secondFont !important;
}
.second_font_600 {
    font-weight: 600 !important;
    font-family: $secondFont !important;
}
.second_font_700 {
    font-weight: 700 !important;
    font-family: $secondFont !important;
}

// font-weight
@for $weight from 1 through 9 {
    .font_#{$weight * 100} {
        font-weight: $weight * 100 !important;
    }
}