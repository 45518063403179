aside {
    display: none;
    @include overlay();
    // padding-inline-end: 90px;
    @include prefix(transition, opacity .2s linear);
    &.show {
        display: block;
    }
    &.animation {
        .side_menu {
            @include prefix(transform, translate(0px, 0));
        }
    }
    .side_menu {
        width: 100%;
        height: 100%;
        padding: 24px;
        max-width: 300px;
        // vars
        background-color: $mainColor;
        // mixin
        @include prefix(transform, translate(100%, 0));
        @include prefix(transition, transform .2s linear);
        .wrap_logo {
            margin: 0 auto;
            max-width: 240px;
        }
        ul {
            overflow: auto;
            max-height: 80%;
            border: 1px solid;
            border-color: rgba($white, 50%) transparent rgba($white, 50%) transparent;
            li {
                font-weight: 500;
                font-family: $mainFont;
                a {
                    color: $white;
                    @include prefix(transition, color .3s linear);
                }
                &:hover, &.active {
                    a {
                        color: $secondColor;
                    }
                }
            }            
        }
        .lang {
            min-width: 86px;
            font-size: 14px; 
            min-height: 46px;
            font-weight: 500;   
            border-radius: 16px 0;
            text-decoration: none;
            // vars
            color: $white;
            background: rgba($black, 0.06);
        }        
    }
}