.app_btn {
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    min-height: 48px;
    // line-height: 1.5px;
    padding: 8px 35px;
    position: relative;
    font-family: $mainFont;
    border: 1px solid currentColor;
    &::before {
        top: 0;
        right: 0;
        left: 100%;
        z-index: -1;
        content: '';
        height: 100%;
        opacity: 15%;
        position: absolute;
        background-color: currentColor !important;
        @include prefix(transition, all .1s linear);
    }
    &:hover {
        // vars
        // color: $white;
        border-color: currentColor !important;
        &::before {
            left: 0px;
        }
    }
    svg {
        max-width: 40px;
        max-height: 15px;
    }
}