.account_statistics {
    .wrap_statistics {
        .wrap_img {
            width: 100%;            
            max-width: 184px;
            &::after {
                inset: 0;
                z-index: 1;
                content: '';
                position: absolute;
                background: linear-gradient(180deg, #1F7F52BF 0%, #FFFFFF00 100%);
            }
        }
        .wrap_details {
            ul li {
                width: calc(100% / 2);
            }
            .progress_wrap {
                height: 16px;
                padding: 0px 2px;
                // vars
                background-color: #D9D9D9;
                // mixin
                @include prefix(border-radius, 3px);                
                .progress_bar {
                    width: 75%;
                    height: 12px;
                    // vars
                    background-color: $mainColor;
                    // mixin
                    @include prefix(border-radius, 3px);
                    
                }
            }
        }
    }
}