.app_popup_pay {
    display: none !important;
    padding: 30px 12px;
    @include overlay();
    @include prefix(transition, opacity .2s linear);
    &.show {
        display: flex !important;
    }
    &.animation {
        .wrap_pay, .pay_details {
            @include prefix(transform, translate(0px, 0px));
        }
    }
    .wrap_content {
        // overflow-y: auto;
        // max-height: 850px;
    }
    .pay_details {
        width: 100%;
        max-width: 530px;
        display: none !important;
        // mixin
        @include prefix(transform, translate(0px, 500px));
        @include prefix(transition, transform .1s linear);        
        &.show {
            display: block !important;
        }        
    }
    .wrap_pay {
        width: 100%;
        max-width: 533px;
        min-height: 650px;
        display: none !important;
        padding: 47px 27px 20px 27px;
        // vars
        background-color: $white;
        // mixin
        @include prefix(transform, translate(0px, 500px));
        @include prefix(transition, transform .1s linear);
        &.show {
            display: flex !important;
        }
        .wrap_sides {
            // width: calc(100% / 2);
            .logo {
                max-width: 189px;
            }
            .payment_method {
                gap: 28px;
            }
            form {
                .app_btn {
                    padding: 18px 35px;
                }
            }
        }
    }
    .wrap_alert_msg {
        width: 100%;
        display: none;
        max-width: 456px;
        padding: 30px 40px;
        // vars
        background-color: $white;
        // mixin
        @include prefix(transform, translate(0px, 500px));
        @include prefix(transition, transform .1s linear);
        &.animation {
            // mixin
            @include prefix(transform, translate(0px, 0px));
        }        
        &.show {
            display: block;
        }
        .back_to_Home {
            padding: 18px 35px !important;
        }
    }
}