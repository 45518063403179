.pay_details {
    background-color: $white;
    .types {
        text-align: center;
        li {
            cursor: pointer;
            padding: 15px 5px;
            border-bottom: 1px solid rgba($parag, 50%);         
            &.active {
                border-radius: 10px 10px 0 0;
                // var
                color: $white;
                border-color: $mainColor;
                background-color: $mainColor;
            }
        }
    }
    .prices {
        gap: 10px;
        li {
            input {
                &:checked + label {
                    span {
                        // var
                        color: $white;
                    }
                    &::after {
                        scale: 1;
                        @include prefix(border-radius, 0px);
                    }
                }
            }            
            label {
                z-index: 1;
                &::after {
                    inset: 0;
                    scale: 0;
                    content: '';
                    z-index: -1;
                    position: absolute;
                    // vars
                    background-color: $mainColor;
                    // mixin
                    @include prefix(border-radius, 9999px);
                    @include prefix(transition, all .2s linear);
                }
            }
    
        }
    }
    form {
        .col-12:first-of-type {
            .app_input_field {
                    // margin-block-end: 30px;
                input {
                    // var
                    border-color: rgba($parag, 50%);
                }
            }
        }
        .gift_donation_box {
            display: none;
        }
        select {
            width: 100%;
            padding: 0 15px;
            font-size: 14px;
            font-weight: 500;
            appearance: none;            
            // padding: 17px 10px;
            min-height: 48px;
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16 10L12 14L8 10" stroke="%235D686F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat 10px center;
            // var
            color: $parag_2;
            border: 1px solid rgba($parag, 50%);
            option {
                font-size: 14px;
                font-weight: 500;
                color: $parag_2;
                padding: 17px 10px;
            }
        }
        .payment_method {
            gap: 20px;
            li {
                cursor: pointer;
                min-width: 80px;
                padding: 6px 8px;
                border: 1px solid $headingColor_6;
                &.active {
                    border-color: $mainColor;
                }
                svg {
                    width: 37px;
                    height: 37px;
                }
            }
        }
        .terms {
            margin-bottom: 22px;
        }
        .app_btn {
            padding: 18px 35px;
        }
    }
}