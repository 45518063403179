.terms_privacy_policy {
    h1 {
        line-height: 90px;
        padding: 100px 10px;
        background-color: #FBFBFB;
        background: url('/src/assets/img/shape.svg') 0px bottom no-repeat;
    }
    .wrap_content {
        width: 100%;
        margin: 0 auto;
        max-width: 800px;
        p {
            line-height: 35px;
        }
    }
}