@mixin prefix($name, $value) {
  #{$name}: $value;
  -o-#{$name}: $value;
  -moz-#{$name}: $value;
  -webkit-#{$name}: $value;
}

@mixin overlay($opc: null) {
  inset: 0;
  z-index: 500;
  position: fixed;
  @if $opc {
    background-color: rgba($black, $opc);
  }
  @else {
    background-color: rgba($black, 70%);
  }
}