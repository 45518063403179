.navbar {
    padding-top: 30px;
    padding-bottom: 25px;
    @include prefix(box-shadow, 0px 4px 10px 0px rgba($black, 0.06));
    .wrap_navbar {
        .lang {
            min-width: 86px;        
            font-size: 14px; 
            min-height: 56px;
            font-weight: 500;   
            border-radius: 16px 0;
            text-decoration: none;
            // vars
            color: $black;
            background: rgba($black, 0.06);
        }
        .navbar-collapse {
            width: 100%;
            justify-content: center;
            padding-inline-end: 140px;            
            padding-inline-start: 75px;
            ul li {
                a {
                    svg {
                        stroke: $headingColor;
                    }
                    &.active {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 800 !important;
                        // var
                        color: $mainColor !important;
                        svg {
                            stroke: $mainColor;
                        }
                    }
                }
            }
        }
        .options {
            .account {
                button {
                    padding: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    // line-height: normal;
                    @include prefix(transition, background-color .2s linear);
                    &.user {
                        color: $white;
                        padding: 8px 35px;
                        border-radius: 20px 0;
                        background-color: $mainColor;
                    }
                    // vars
                    color: $secondColor;
                    font-family: $secondFont;
                    border: 1px solid $secondColor;
                    &:hover {
                        background-color: $green_400 !important;
                    }                    
                }
            }
            .backward {
                padding: 10px;
                vertical-align: unset;
                background: linear-gradient(264deg, $green_600 10.11%, $secondColor 102.27%);
            }
        }
    }
}