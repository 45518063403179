.my_account {
    form {
        .wrap_gender {
            .wrap_radio {
                width: calc(100% / 2);
                &:first-of-type {
                    margin-inline-end: 24px;
                }
                input:checked + label {
                    // vars
                    color: $mainColor;
                    border-color: currentColor;
                    span {
                        color: currentColor;
                        &.wrap_checked {
                            border-color: currentColor;
                            .checked {
                                display: inline-block;
                            }
                        }
                    }
                }
                label {
                    max-height: 48px;                    
                    padding: 16px 13px;
                    // vars
                    color: $parag;
                    border: 1px solid currentColor;
                    .wrap_checked {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        display: inline-block;         
                        // vars       
                        border: 3px solid currentColor;
                        // mixin
                        @include prefix(border-radius, 9999px);
                        .checked {
                            top: 50%;
                            left: 50%;
                            width: 11.5px;
                            height: 11.5px;
                            display: none;
                            position: absolute;
                            // vars
                            background-color: $mainColor;
                            // mixin
                            @include prefix(border-radius, 100px);
                            @include prefix(transform, translate(-50%, -50%));
                        }
                    }
                }
            }
        }
        .wrap_checkBox {
            .app_checkbox {
                &:first-of-type {
                    margin-bottom: 20px;
                }
                input {
                    width: 24px;
                    height: 24px;
                    margin-inline-end: 9px;
                    //
                    border-color: $parag;
                    background-color: $parag;
                    &:checked {
                        border-color: $mainColor;
                        background-color: $mainColor;
                    }
                    &:checked + label {
                        color: $mainColor;
                        font-weight: 500 !important;
                    }
                }
                label {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    // vars
                    color: $parag;
                    font-family: $secondFont !important;
                }
            }
        }
        .submit {
            .app_btn {
                width: 100%;
                max-width: 296px;
            }
        }
    }
}