.loading_screen {
    inset: 0;
    z-index: 1000;
    padding: 12px;
    position: fixed;
    // vars
    background-color: $white;
    // mixin
    @include prefix(transition, opacity .3s linear);
    &.hide {
        opacity: 0;
    }
    .wrap_logo {
        width: 100%;
        max-width: 350px;
        text-align: center;
        animation: scaling_animate 1.1s linear infinite forwards;
    }
}

@keyframes scaling_animate {
    0% {
        scale: .8;
    }
    50% {
        scale: 1.5;
    }
    100% {
        scale: .8;
    }
}