@media (max-width: 576px) {
    .product_details {
        .pay_details {
            h4 {
                font-weight: 700;
                line-height: 22px;
                margin-block-end: 16px;
                font-size: 14px !important;
            }
            .prices {
                li {
                    // &:last-of-type {
                    //     margin-inline-end: 0px !important;
                    // }
                    // &:not(:last-of-type) {
                    //     margin-inline-end: 5px !important;
                    // }
                    label {
                        padding: 13px !important;
                        font-size: 14px !important
                    }
                }
            }
            form {
                .app_checkbox {
                    label {
                        font-size: 12px !important;
                    }
                }
            }
        }
        .sponsor {
            .wrap_img {
                min-height: 85px;
                max-width: 112px !important;
                padding: 16px 6px !important;
                margin-inline-end: 12px !important
            }
            h4 {
                font-size: 12px !important;
            }
        }
    }
    .account_statistics {
        .wrap_statistics {
            .wrap_img {
                margin-block-end: 10px;
                margin-inline-end: 0px !important;
            }
            .wrap_details {
                ul li {
                    // width: calc(100% / 2);
                }
            }
        }
    }
    .terms_privacy_policy h1 {
        line-height: 60px;
        padding-bottom: 120px;
        font-size: 20px !important;
    }
}

@media (max-width: 991px) {
    .account {
        .acc_side_menu  {
            margin-bottom: 32px;
            min-height: 500px;
        }
    }
    .notifications {
        .tabs li {
            margin-inline-end: 0px !important
        }
        .dates li .wrap_right {
            margin-bottom: 10px;
        }
    }
    .auth_pages {
        background: none !important;
        .wrap_form {
            padding: 16px;
            // vars
            background-color: $white;
            // mixin
            @include prefix(border-radius, 12px);
            @include prefix(box-shadow, 2px 10px 20px 15px rgba($black, 4%));            
        }
        .panner {
            margin-bottom: -70%;
            .wrap_panner {
                overflow: hidden;
                @include prefix(border-radius, 15px);
                img {
                    top: -60px;                    
                    z-index: -1;
                    position: relative;
                    filter: blur(1px);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .account_donations {
        .counters  {
            max-width: none !important;
        }
    }
    .register_non_profit_org {
        header {
            background-size: auto;
        }
        .steppers ul li {
            &.completed .step_title {
                display: none !important;
            }
            &.active .step_title {
                display: block !important;
                font-size: 12px !important;            
                // vars
                color: $mainColor;
            }
        }
        .steppers_buttons {
            .btn {
                max-width: 100% !important;
                &:first-of-type {
                    margin-block-end: 16px;
                    margin-inline-end: 0px;
                }
            }
        }
    }
}