.app_icon {
    z-index: 1;
    width: 48px;
    height: 48px;
    position: relative;
    overflow: hidden;
    @include prefix(border-radius, 8px);
    &::after {
        inset: 0;
        scale: 0;
        content: '';
        z-index: -1;
        position: absolute;
        background-color: currentColor;
        @include prefix(border-radius, 999px);
        @include prefix(transition, all .2s linear);
    }
    &.square {
        border: 1px solid $white;
    }
    &:hover {
        &::after {
            scale: 1;
            @include prefix(border-radius, 0px);
        }
    }
}