.app_breadcrumb {
    padding: 25px 0;
    // background: url('/src/assets/img/Group\ 1410106996.svg') right center no-repeat;
    background-size: cover;
    ol {
        padding-inline-end: 30px;
        li {
            &:not(:first-of-type) {
                &::before {
                    padding: 0 8px !important;
                    content: url('/src/assets/img/arrow_top-right.svg') !important;
                }
            }
            a {
                opacity: 0.8;
                // vars
                color: $parag;
                font-family: $mainFont;
            }
        }
    }
}