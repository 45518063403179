.auth_pages {
    padding: 40px 0;
    min-height: 100vh;
    background: url('/src/assets/img/auth_bg.png') top left no-repeat;
    background-size: 35% 100%;
    &.registration {
        .hr {
            margin: 24px auto !important;
        }
    }
    .logo {
        width: 100%;
        max-width: 204px;
    }
    form {
        .app_input_field {
            &.email svg {
                stroke: $gray_500;
                fill: none !important;
            }
            .wrap_input {
                // var
                .wrap_icon {
                    right: 0px;
                    left: auto !important;
                }
                input {
                    min-height: 62px;
                    // vars
                    border: 1px solid $gray_300;
                    &::placeholder {
                        color: rgba($parag_3, 50%);
                        font-size: 16px !important;
                    }
                }
            }
        }
        .app_checkbox_input {
            border-radius: 2px;
            border-color: #C4C4C4;
            background-color: #C4C4C4;
        }
        .submit {
            margin-top: 73px;
            .login {
                min-height: 56px;
            }
            .register {
                min-height: 62px
            }
            .hr {
                height: 1px;
                width: 185px;
                margin: 56px auto;
                // var
                background-color: rgba($parag, 30%);
            }
        }
    }
}