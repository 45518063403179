@media (max-width: 576px) {
    // start app btn
    .app_btn {
        font-size: 12px;
        // min-height: 32px;
        padding: 10px 11px;
    }
    // end app btn

    // start app card
    .app_card {
        padding: 8px;
        .wrap_top {
            .cost {
                line-height: normal;
                padding: 25px 40px !important;
                .amount {
                    span {
                        font-size: 12px !important;
                    }
                }
            }
        }  
        .wrap_mid {
            margin-block-start: 10px;
            h3 {
                line-height: 22px; /* 157.143% */
                span:first-of-type {
                    font-size: 16px !important;
                }
                span:last-of-type {
                    font-size: 14px !important;
                }
            }
            p {
                font-size: 12px;
            }
        }  
        .wrap_bottom {
            margin-block-start: 28px;
            .cates {
                li {
                    font-size: 12px !important;
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            .more_details {
                margin-block-start: 12px    
            }
            .wrap_input {
                input {
                    font-size: 14px;
                }
                .wrap_pay span {
                    font-size: 12px !important;
                }
            }
        }
    }
    // end app card

    // start app navbar
    .navbar {
        .logo {
            max-width: 110px;
        }
        .options {
            .account button {
                margin-inline-end: 10px !important;
            }
        }
    }  
    // end app navbar

    // start app sidebar
    // aside {
    //     .side_menu {
    //         max-width: 300px !important;
    //     }
    // }  
    // end app sidebar        

    // start app header
    header, .header_panner, .carousel_panner {
        h1 {
            font-weight: 500;
            line-height: 30px;
            font-size: 24px !important; 
        }
    }  
    // end app header
    
    // start app popup pay
    .app_popup_pay {
        &.show {
            // for fix scrolling in sm screen
            display: block !important;
        }
        &.hasAlertMsg {
            padding-top: 110px;
        }        
        // end app popup pay
        .pay_details  {
            .types li {
                font-size: 12px !important;
            }
            #fastDonateTypeDes {
                font-size: 12px !important;
            }
            .prices li label {
                font-size: 12px !important;
                padding: 10px !important;
            }
            form {
                .app_input_field {
                    // margin-block-end: 16px !important;
                    input {
                        font-size: 12px !important;
                        &::placeholder {
                            font-size: 12px !important;
                        }
                    }                    
                }
                .payment_method  img {
                    width: 32px;
                }
                .wrap_btnn .app_btn {
                    padding: 10px !important;
                    font-size: 12px !important;
                    &.apple_pay_btn {
                        img {
                            margin: 0 !important;
                        }
                        span {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

}

// min-768px
@media (min-width: 768px) {
    aside {
        display: none !important;
    }
}

// max-768px
@media (max-width: 767.98px) {

    // start app card
    .app_card {
        .wrap_bottom .details li {
            font-size: 12px !important;
            padding: 12px 5px !important;
            span:last-of-type {
                font-size: 14px !important;
            }
        }
    }
    // end app card


    // start app popup pay
    .app_popup_pay {
        overflow-y: auto;
        .wrap_pay {
            min-height: auto !important;
            padding: 25px !important;
            .wrap_sides {
                width: 100% !important;
                h4 {
                    margin-block-end: 5px !important;
                    font-size: 14px !important;
                    & + span {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
    // end app popup pay

    // start app fast donate
    .fast_donate {
        top: 410px !important;
    }
    // end app fast donate

    // start app navbar
    .navbar {
        .logo {
            max-width: 141px;
        }
        .options {
            .account {
                button {
                    line-height: normal;
                    width: 40px !important;
                    height: 40px !important;
                    display: flex !important;
                    padding: 10px !important;
                    border-radius: 8px !important;
                    background-color: $white !important;
                    svg path {
                        fill: $mainColor;
                    }
                    img {
                        width: 20px;
                        height: 20px;
                        margin-inline-end: 0px !important;
                    }
                }
            }
        }
    }  
    // end app navbar

    // start app sidebar
        // aside {
        //     padding-inline-end: 40px;
        //     .side_menu {
        //         max-width: 380px;
        //         padding: 20px 24px;
        //         .actions {
        //             button {
        //                 line-height: 1.5px;
        //             }
        //         }            
        //         .icons {
        //             a {
        //                 &:last-of-type {
        //                     margin-inline-end: 16px
        //                 }
        //             }
        //             .app_icon {
        //                 width: 32px !important;
        //                 height: 32px !important;
        //                 img {
        //                     width: 13.5px;
        //                 }
        //             }
        //         }
        //         ul {
        //             li {
        //                 font-size: 18px !important;
        //                 margin-bottom: 20px !important;
        //             }
        //         }
        //     }
        // }  
    // end app sidebar
}



@media (min-width: 992px) {
    .app_card {  
        &.full_details {
            .wrap_top {
                .panner {
                    height: 400px !important;
                }
            }
        }  
    }
}

@media (min-width: 992px) and (max-width: 1399px) {

    // start app card
    .app_card {
        .wrap_top .cost .amount {
            display: flex;
            text-align: center;
            justify-content: center;
            flex-direction: column;
        }
        .wrap_bottom {

            .details li {
                text-align: center;
                width: 100% !important;
                margin-inline-end: 0px !important;
            }
        }
    }
    // end app card
}

@media (min-width: 1200px) {
    .app_card {
        &.smallCard {
            .more_details {
                // flex-direction: column-reverse;
                .wrap_icon, .more_details_btn {
                    // width: 100%;
                    &.wrap_icon {
                        // margin-bottom: 10px;
                        align-items: center;
                        justify-content: center;
                        & > div {
                            // width: 50%;
                        }
                    }
                    &.more_details_btn {
                        margin-inline-end: 5px !important;
                    }
                }
            }
        } 
    }    
}