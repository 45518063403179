.app_input_field {
    &.hasIcon .wrap_input input {
        padding-inline-start: 50px;
    }
    &.is_valid {
        .wrap_input {
            .wrap_icon, .wrap_password_icon {
               svg {
                   fill: $mainColor !important;
               }
            }
            input {
                // vars
                color: $mainColor !important;
                border-color: $mainColor !important;
           }
        }
    }
    &.is_invalid {
        .wrap_input {
            .wrap_icon, .wrap_password_icon  {
                svg {
                    fill: var(--bs-danger) !important;
                }
            }
            input {
                color: var(--bs-danger) !important; 
                // vars
                border-color: var(--bs-danger) !important;
           }
        }        
    }
    label {
        font-size: 14px;
        font-weight: 500;
        // vars
        color: $writing;
        font-family: $secondFont;
        .required {
            color: #E3171A;
        }
    }
    .wrap_input {
        .wrap_icon {
            top: 50%;
            left: 0px;
            padding: 0 10px;
            // vars
            // background: $white;
            // mixin
            @include prefix(transform, translate(0, -50%));
            svg {
                fill: $gray_500
            }
        }
        .wrap_password_icon, .wrap_upload_icon {
            left: 0;
            width: 50px;            
            &.wrap_password_icon {
                cursor: pointer;
                svg {
                    // var
                    fill: $writing;
                }
            }
        }
        .override_label_file {
            border: 0px;
            width: 100%;
            direction: rtl;
            font-size: 16px;
            min-height: 48px;
            font-weight: 500;
            padding: 12px 15px;
            // vars
            font-family: $mainFont;
            color: $parag_2 !important;
            border: 1px solid rgba($parag_2, 50%);
            .wrap_file_icon svg {
                fill: $writing;
            }
            .file_placeholder {
                font-size: 16px;
                font-weight: 400;
                // vars
                color: $parag;
                font-family: $secondFont;
            }
        }
        input {
            border: 0px;
            direction: rtl;
            font-size: 16px;
            min-height: 48px;
            font-weight: 500;
            padding: 12px 15px;
            // vars
            font-family: $mainFont;
            color: $parag_2 !important;
            border: 1px solid rgba($parag_2, 50%);
            &::placeholder {
                font-size: 14px;
                // vars        
                color: $parag_2;
            }
            &::file-selector-button {
                display: none;
            }
            &[type="date"] {
                display: flex;
                text-align: end;
                flex-direction: row-reverse;
                &::-o-calendar-picker-indicator {
                    background-image: url('/src/assets/img/fi_2669764.svg');
                }
                &::-moz-calendar-picker-indicator {
                    background-image: url('/src/assets/img/fi_2669764.svg');
                }
                &::-ms-calendar-picker-indicator {
                    background-image: url('/src/assets/img/fi_2669764.svg');
                }                                                
                &::-webkit-calendar-picker-indicator {
                    background-image: url('/src/assets/img/fi_2669764.svg');
                }
            }
            &:focus {
                box-shadow: none;
            }
            &[type="password"] {
                padding-inline-end: 50px;
            }
        }
    }
    .invalid-feedback {
        opacity: 0.7;
        font-size: 14px;
        font-weight: 500;
        margin-block-start: 16px;
        // vars
        font-family: $mainFont;
    }
}