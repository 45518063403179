.drop_down_menu {
    padding: 10px;
    min-width: 160px;
    border-radius: 10px;
    display: inline-block;
    font-weight: 500;
    font-family: $secondFont;
    border: 1px solid $blue_900;
    .drop_down_name {
        color: $blue_900;
    }
    ul {
        display: none;
        &.show {
            display: block;
        }
    }
}