footer {
    margin-top: 85px;
    @include prefix(box-shadow, 0px 8px 10px 10px rgba($black, 6%));
    .description {
        max-width: 270px;
        line-height: 35px;
    }
    h3 {
        font-size: 16px;
        font-weight: 700;
        // vars
        color: $headingColor;
        // font-family: $secondFont;
    }
    .links {
        li {
            &:hover a {
                color: $mainColor;
            }
            a {
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
                // vars
                color: $headingColor;
                // font-family: $secondFont;
                @include prefix(transition, .1s linear)
            }
        }
    }
    .bt_footer {
        // var
        border-top: 1px solid rgba($parag_2, 20%);
        .scroll_to_top {
            width: 47px;
            height: 47px;
            border-radius: 16px 0;
            // vars
            background-color: $secondColor;
        }
    }
}