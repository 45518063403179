/*Start Variables Media Query*/
$media-xs: (max-width: 540px);
$media-sm: (min-width: 541px);
$media-md: (min-width: 720px);
$media-lg: (min-width: 960px);
$media-xl: (min-width: 1140px);
/*End Variables Media Query*/

/*Start Variables Fonts*/
$mainFont: LoewNextArabic;
$secondFont: IBMPlexSansArabic;
/*End Variables Fonts*/

/*Start Variables Colors*/
$mainColor: #007F5C;
$secondColor: #69B244;

//
$headingColor: #1A1A1A;
$headingColor_2: #262626;
$headingColor_3: #4D4D4D;
$headingColor_4: #808080;
$headingColor_5: #B3B3B3;
$headingColor_6: #E6E6E6;

//
$parag: #727580;
$white: #FFFFFF;
$black: #000000;
$writing: #4D4E5F;
$parag_2: #96979E;
$parag_3: #5D686F;
$gradient: #7AC255;
$green_950: #09251A;
$green_900: #13412E;
$green_800: #164F36;
$green_700: #186442;
$green_600: #1F7F52;
$green_400: #7EBF5C;
$green_300: #A1D284;
$green_200: #C5E5B1;
$green_100: #E1F1D6;
$green_50: #F2F9EC;
$blue_950: #063046;
$blue_900: #144A65;
$blue_800: #125978;
$blue_700: #0A6E94;
$gray_300: #D0D5DD;
$gray_500: #667085;


/*End Variables Colors*/

/*Start Variables Helper*/
$default_opc: 0.63;
/*End Variables Helper*/

