@import 'variables/variables';

@import 'mixin/mixin';

@import 'helper/*';

@import 'components/*';

@import 'layout/navbar';

@import 'layout/_side_menu';

@import 'layout/account_side_menu';

@import 'pages/*';

@import 'layout/footer';

@import 'layout/loading_screen';

@import 'media-query/root';

@import '../fonts/font';

.close_icon {
    cursor: pointer;
    border-radius: 999px;
    margin: 0 auto 15px auto;
    // vars
    stroke: $white;
    border: 2px solid $white;
    // mixin
    @include prefix(transition, transform .1s linear);
    &:hover {
        stroke: $mainColor;
        border-color: $mainColor;
    }
}
