.app_checkbox {
    .app_checkbox_input {
        // vars
        border: 2px solid $parag;
    }
    label {
        font-size: 14px;
        font-weight: 500;
        // vars
        color: $writing;
        font-family: $mainFont;
    }
}