.register_non_profit_org {
    header {
        min-height: 81px;
        margin-block-end: 20px;
        background-size: cover;
        & + h4 {
            font-size: 18px;
        }
    }
    .steppers {
        ul {
            overflow: hidden;
            li {
                position: relative;
                float: inline-start;
                width: calc(100% / 4);
                &:first-of-type, &:last-of-type {                    
                    &:first-of-type {
                        text-align: start;
                        div {
                            position: relative;
                            &::after {
                                top: 0;
                                right: 0;
                                width: 50%;
                                z-index: -1;
                                height: 50%;
                                content: '';
                                position: absolute;
                                // vars
                                background-color: $white;
                            }
                        }
                    }
                    &:last-of-type {
                        text-align: end;
                        div {
                            position: relative;
                            &::before {
                                top: 0;
                                left: 0;
                                width: 50%;
                                z-index: -1;
                                height: 50%;
                                content: '';
                                position: absolute;
                                // vars
                                background-color: $white;
                            }
                        }                      
                    }                    
                }
                &::after, &::before {
                    // right: 0;
                    top: 16px;
                    z-index: -2;
                    height: 1px;
                    width: 50%;
                    content: '';
                    position: absolute;
                    // vars
                    background-color: rgba($parag_2, 50%);
                }               
                &::before {
                    left: 50%;
                }
                &::after {
                    left: 0%;
                }
                &:last-of-type {
                    &.active {
                        &::after {
                            background-color: $mainColor;
                        }                   
                    }
                }
                &.active, &.completed {
                    &.completed {
                        & + ::before, &::after, &::before {
                            // vars
                            background-color: $mainColor;
                        }
                        div {
                            .step_title {
                                // vars
                                color: $mainColor;
                            }
                        }
                    }
                    div {
                        span {
                            &.step_number {
                                // vars
                                color: $white;                                
                                background-color: $mainColor;
                            }
                        }
                    }
                }
                span {
                    margin: 0 auto;
                    display: block;
                    &.step_number {
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        margin-bottom: 16px;
                        vertical-align: middle;
                        background-color: #D9D9D9;
                        // vars
                        color: $parag;
                        // mixin
                        @include prefix(border-radius, 9999px);
                    }
                }
            }
        }
    }
    form {
        .wrap_step {
            min-height: 690px;
            &.show {
                display: flex !important;
            }
        }
        .app_input_field .wrap_input {
            .wrap_icon {
                right: 0;
                left: auto !important;
            }            
            input {
                padding-inline-start: 32px;
            }
        }
        .steppers_buttons {
            .btn {
                width: 100%;
                max-width: 295px;
                min-height: 56px;
            }
        }      
    }
    .complete {
        display: none !important;
        @include overlay(40%);
        // mixin
        @include prefix(transition, opacity .2s linear);
        &.show {
            display: flex !important;
        }
        &.animation {
            .wrap_alert_complete {
                @include prefix(transform, translate(0px, 0px));
            }
        }        
        p {
            line-height: 24px
        }
        .wrap_alert_complete {
            width: 100%;
            max-width: 512px;
            // vars
            background-color: $white;
            // mixin
            @include prefix(transform, translate(0px, 500px));
            @include prefix(transition, transform .1s linear);
            .app_btn {
                width: 100%;
                min-height: 56px;
                margin-top: 61px;
                max-width: 296px;
            }
        }
    }
}