/*Start Variables Media Query*/
/*End Variables Media Query*/
/*Start Variables Fonts*/
/*End Variables Fonts*/
/*Start Variables Colors*/
/*End Variables Colors*/
/*Start Variables Helper*/
/*End Variables Helper*/
.container {
  max-width: 1400px !important;
}

.my_container {
  width: 100%;
  margin: 0 auto;
  max-width: 1400px;
  padding-inline-end: 12px;
  padding-inline-start: 12px;
}

body {
  overflow: hidden;
  font-family: LoewNextArabic;
}

.pointer {
  cursor: pointer;
}

.hover_main_bg {
  transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out;
}
.hover_main_bg:hover {
  color: #FFFFFF;
  background: #13412E;
}

.app_card_v1 {
  padding: 24px;
  border-radius: 12px;
  -o-border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
}

.app_card_v2 {
  padding: 16px;
  border-radius: 12px;
  -o-border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
  -o-box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
}

.text_under_none {
  text-decoration: none;
}

.dir_rtl {
  direction: rtl !important;
}

.dir_ltr {
  direction: ltr !important;
}

.obj_img_cover {
  object-fit: cover;
}

.border_1 {
  border: 1px solid #727580;
}

.border_0_5 {
  border: 0.5px solid #727580;
}

.main_color {
  color: #007F5C !important;
}

.second_color {
  color: #69B244 !important;
}

.heading_color {
  color: #1A1A1A !important;
}

.header_color_2 {
  color: #262626 !important;
}

.header_color_3 {
  color: #4D4D4D !important;
}

.header_color_4 {
  color: #808080 !important;
}

.header_color_5 {
  color: #B3B3B3 !important;
}

.header_color_6 {
  color: #E6E6E6 !important;
}

.parag {
  color: #727580;
}

.writing {
  color: #4D4E5F;
}

.parag_2 {
  color: #96979E;
}

.green_950 {
  color: #09251A;
}

.green_900 {
  color: #13412E;
}

.green_800 {
  color: #164F36;
}

.green_700 {
  color: #186442;
}

.green_600 {
  color: #1F7F52;
}

.green_400 {
  color: #7EBF5C;
}

.green_300 {
  color: #A1D284;
}

.green_200 {
  color: #C5E5B1;
}

.green_100 {
  color: #E1F1D6;
}

.green_50 {
  color: #F2F9EC;
}

.blue_950 {
  color: #063046;
}

.blue_900 {
  color: #144A65;
}

.blue_800 {
  color: #125978;
}

.main_bg {
  background-color: #007F5C !important;
}

.second_bg {
  background-color: #69B244 !important;
}

.parag_bg {
  background-color: #727580;
}

.parag_2_bg {
  background-color: #96979E;
}

.green_bg_50 {
  background-color: #F2F9EC;
}

.blue_bg_900 {
  background-color: #144A65;
}

.blue_bg_800 {
  background-color: #125978;
}

.gradient {
  background: linear-gradient(92deg, #38BA76 -28.2%, #7AC255 147.15%);
}

.border_radius_xs {
  border-radius: 20px 0 !important;
}

.border_radius_xs_reverse {
  border-radius: 0px 20px !important;
}

.border_radius_sm {
  border-radius: 50px 0 !important;
}

.border_radius_sm_reverse {
  border-radius: 0px 50px !important;
}

.border_radius_md {
  border-radius: 100px 0 !important;
}

.border_radius_md_reverse {
  border-radius: 0px 100px !important;
}

.border_radius_lg {
  border-radius: 150px 0 !important;
}

.border_radius_lg_reverse {
  border-radius: 0px 150px !important;
}

.custom_scroll {
  padding-inline-end: 3px !important;
}
.custom_scroll.md {
  padding-inline-end: 5px !important;
}
.custom_scroll.md::-webkit-scrollbar {
  width: 10px;
}
.custom_scroll::-webkit-scrollbar {
  margin: 15px 0;
}
.custom_scroll::-webkit-scrollbar-track {
  margin: 15px 0;
  border-radius: 100px;
  background-color: rgb(219, 217, 217);
}
.custom_scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #144A65;
}

.margin_full {
  margin: 4px;
}

.margin_start {
  margin-inline-start: 4px;
}

.margin_end {
  margin-inline-end: 4px;
}

.margin_top {
  margin-block-start: 4px;
}

.margin_bottom {
  margin-block-end: 4px;
}

.margin_x {
  margin-inline-end: 4px;
  margin-inline-start: 4px;
}

.margin_y {
  margin-block-end: 4px;
  margin-block-start: 4px;
}

.padding_full {
  padding: 4px;
}

.padding_start {
  padding-inline-start: 4px;
}

.padding_end {
  padding-inline-end: 4px;
}

.padding_top {
  padding-block-start: 4px;
}

.padding_bottom {
  padding-block-end: 4px;
}

.padding_x {
  padding-inline-end: 4px;
  padding-inline-start: 4px;
}

.padding_y {
  padding-block-end: 4px;
  padding-block-start: 4px;
}

.border_radius {
  border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.margin_full_2 {
  margin: 8px;
}

.margin_start_2 {
  margin-inline-start: 8px;
}

.margin_end_2 {
  margin-inline-end: 8px;
}

.margin_top_2 {
  margin-block-start: 8px;
}

.margin_bottom_2 {
  margin-block-end: 8px;
}

.margin_x_2 {
  margin-inline-end: 8px;
  margin-inline-start: 8px;
}

.margin_y_2 {
  margin-block-end: 8px;
  margin-block-start: 8px;
}

.padding_full_2 {
  padding: 8px;
}

.padding_start_2 {
  padding-inline-start: 8px;
}

.padding_end_2 {
  padding-inline-end: 8px;
}

.padding_top_2 {
  padding-block-start: 8px;
}

.padding_bottom_2 {
  padding-block-end: 8px;
}

.padding_x_2 {
  padding-inline-end: 8px;
  padding-inline-start: 8px;
}

.padding_y_2 {
  padding-block-end: 8px;
  padding-block-start: 8px;
}

.border_radius_2 {
  border-radius: 8px;
  -o-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.margin_full_3 {
  margin: 12px;
}

.margin_start_3 {
  margin-inline-start: 12px;
}

.margin_end_3 {
  margin-inline-end: 12px;
}

.margin_top_3 {
  margin-block-start: 12px;
}

.margin_bottom_3 {
  margin-block-end: 12px;
}

.margin_x_3 {
  margin-inline-end: 12px;
  margin-inline-start: 12px;
}

.margin_y_3 {
  margin-block-end: 12px;
  margin-block-start: 12px;
}

.padding_full_3 {
  padding: 12px;
}

.padding_start_3 {
  padding-inline-start: 12px;
}

.padding_end_3 {
  padding-inline-end: 12px;
}

.padding_top_3 {
  padding-block-start: 12px;
}

.padding_bottom_3 {
  padding-block-end: 12px;
}

.padding_x_3 {
  padding-inline-end: 12px;
  padding-inline-start: 12px;
}

.padding_y_3 {
  padding-block-end: 12px;
  padding-block-start: 12px;
}

.border_radius_3 {
  border-radius: 12px;
  -o-border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}

.margin_full_4 {
  margin: 16px;
}

.margin_start_4 {
  margin-inline-start: 16px;
}

.margin_end_4 {
  margin-inline-end: 16px;
}

.margin_top_4 {
  margin-block-start: 16px;
}

.margin_bottom_4 {
  margin-block-end: 16px;
}

.margin_x_4 {
  margin-inline-end: 16px;
  margin-inline-start: 16px;
}

.margin_y_4 {
  margin-block-end: 16px;
  margin-block-start: 16px;
}

.padding_full_4 {
  padding: 16px;
}

.padding_start_4 {
  padding-inline-start: 16px;
}

.padding_end_4 {
  padding-inline-end: 16px;
}

.padding_top_4 {
  padding-block-start: 16px;
}

.padding_bottom_4 {
  padding-block-end: 16px;
}

.padding_x_4 {
  padding-inline-end: 16px;
  padding-inline-start: 16px;
}

.padding_y_4 {
  padding-block-end: 16px;
  padding-block-start: 16px;
}

.border_radius_4 {
  border-radius: 16px;
  -o-border-radius: 16px;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
}

.margin_full_5 {
  margin: 20px;
}

.margin_start_5 {
  margin-inline-start: 20px;
}

.margin_end_5 {
  margin-inline-end: 20px;
}

.margin_top_5 {
  margin-block-start: 20px;
}

.margin_bottom_5 {
  margin-block-end: 20px;
}

.margin_x_5 {
  margin-inline-end: 20px;
  margin-inline-start: 20px;
}

.margin_y_5 {
  margin-block-end: 20px;
  margin-block-start: 20px;
}

.padding_full_5 {
  padding: 20px;
}

.padding_start_5 {
  padding-inline-start: 20px;
}

.padding_end_5 {
  padding-inline-end: 20px;
}

.padding_top_5 {
  padding-block-start: 20px;
}

.padding_bottom_5 {
  padding-block-end: 20px;
}

.padding_x_5 {
  padding-inline-end: 20px;
  padding-inline-start: 20px;
}

.padding_y_5 {
  padding-block-end: 20px;
  padding-block-start: 20px;
}

.border_radius_5 {
  border-radius: 20px;
  -o-border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.margin_full_6 {
  margin: 24px;
}

.margin_start_6 {
  margin-inline-start: 24px;
}

.margin_end_6 {
  margin-inline-end: 24px;
}

.margin_top_6 {
  margin-block-start: 24px;
}

.margin_bottom_6 {
  margin-block-end: 24px;
}

.margin_x_6 {
  margin-inline-end: 24px;
  margin-inline-start: 24px;
}

.margin_y_6 {
  margin-block-end: 24px;
  margin-block-start: 24px;
}

.padding_full_6 {
  padding: 24px;
}

.padding_start_6 {
  padding-inline-start: 24px;
}

.padding_end_6 {
  padding-inline-end: 24px;
}

.padding_top_6 {
  padding-block-start: 24px;
}

.padding_bottom_6 {
  padding-block-end: 24px;
}

.padding_x_6 {
  padding-inline-end: 24px;
  padding-inline-start: 24px;
}

.padding_y_6 {
  padding-block-end: 24px;
  padding-block-start: 24px;
}

.border_radius_6 {
  border-radius: 24px;
  -o-border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
}

.margin_full_7 {
  margin: 28px;
}

.margin_start_7 {
  margin-inline-start: 28px;
}

.margin_end_7 {
  margin-inline-end: 28px;
}

.margin_top_7 {
  margin-block-start: 28px;
}

.margin_bottom_7 {
  margin-block-end: 28px;
}

.margin_x_7 {
  margin-inline-end: 28px;
  margin-inline-start: 28px;
}

.margin_y_7 {
  margin-block-end: 28px;
  margin-block-start: 28px;
}

.padding_full_7 {
  padding: 28px;
}

.padding_start_7 {
  padding-inline-start: 28px;
}

.padding_end_7 {
  padding-inline-end: 28px;
}

.padding_top_7 {
  padding-block-start: 28px;
}

.padding_bottom_7 {
  padding-block-end: 28px;
}

.padding_x_7 {
  padding-inline-end: 28px;
  padding-inline-start: 28px;
}

.padding_y_7 {
  padding-block-end: 28px;
  padding-block-start: 28px;
}

.border_radius_7 {
  border-radius: 28px;
  -o-border-radius: 28px;
  -moz-border-radius: 28px;
  -webkit-border-radius: 28px;
}

.margin_full_8 {
  margin: 32px;
}

.margin_start_8 {
  margin-inline-start: 32px;
}

.margin_end_8 {
  margin-inline-end: 32px;
}

.margin_top_8 {
  margin-block-start: 32px;
}

.margin_bottom_8 {
  margin-block-end: 32px;
}

.margin_x_8 {
  margin-inline-end: 32px;
  margin-inline-start: 32px;
}

.margin_y_8 {
  margin-block-end: 32px;
  margin-block-start: 32px;
}

.padding_full_8 {
  padding: 32px;
}

.padding_start_8 {
  padding-inline-start: 32px;
}

.padding_end_8 {
  padding-inline-end: 32px;
}

.padding_top_8 {
  padding-block-start: 32px;
}

.padding_bottom_8 {
  padding-block-end: 32px;
}

.padding_x_8 {
  padding-inline-end: 32px;
  padding-inline-start: 32px;
}

.padding_y_8 {
  padding-block-end: 32px;
  padding-block-start: 32px;
}

.border_radius_8 {
  border-radius: 32px;
  -o-border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
}

.margin_full_9 {
  margin: 36px;
}

.margin_start_9 {
  margin-inline-start: 36px;
}

.margin_end_9 {
  margin-inline-end: 36px;
}

.margin_top_9 {
  margin-block-start: 36px;
}

.margin_bottom_9 {
  margin-block-end: 36px;
}

.margin_x_9 {
  margin-inline-end: 36px;
  margin-inline-start: 36px;
}

.margin_y_9 {
  margin-block-end: 36px;
  margin-block-start: 36px;
}

.padding_full_9 {
  padding: 36px;
}

.padding_start_9 {
  padding-inline-start: 36px;
}

.padding_end_9 {
  padding-inline-end: 36px;
}

.padding_top_9 {
  padding-block-start: 36px;
}

.padding_bottom_9 {
  padding-block-end: 36px;
}

.padding_x_9 {
  padding-inline-end: 36px;
  padding-inline-start: 36px;
}

.padding_y_9 {
  padding-block-end: 36px;
  padding-block-start: 36px;
}

.border_radius_9 {
  border-radius: 36px;
  -o-border-radius: 36px;
  -moz-border-radius: 36px;
  -webkit-border-radius: 36px;
}

.margin_full_10 {
  margin: 40px;
}

.margin_start_10 {
  margin-inline-start: 40px;
}

.margin_end_10 {
  margin-inline-end: 40px;
}

.margin_top_10 {
  margin-block-start: 40px;
}

.margin_bottom_10 {
  margin-block-end: 40px;
}

.margin_x_10 {
  margin-inline-end: 40px;
  margin-inline-start: 40px;
}

.margin_y_10 {
  margin-block-end: 40px;
  margin-block-start: 40px;
}

.padding_full_10 {
  padding: 40px;
}

.padding_start_10 {
  padding-inline-start: 40px;
}

.padding_end_10 {
  padding-inline-end: 40px;
}

.padding_top_10 {
  padding-block-start: 40px;
}

.padding_bottom_10 {
  padding-block-end: 40px;
}

.padding_x_10 {
  padding-inline-end: 40px;
  padding-inline-start: 40px;
}

.padding_y_10 {
  padding-block-end: 40px;
  padding-block-start: 40px;
}

.border_radius_10 {
  border-radius: 40px;
  -o-border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}

.margin_full_11 {
  margin: 44px;
}

.margin_start_11 {
  margin-inline-start: 44px;
}

.margin_end_11 {
  margin-inline-end: 44px;
}

.margin_top_11 {
  margin-block-start: 44px;
}

.margin_bottom_11 {
  margin-block-end: 44px;
}

.margin_x_11 {
  margin-inline-end: 44px;
  margin-inline-start: 44px;
}

.margin_y_11 {
  margin-block-end: 44px;
  margin-block-start: 44px;
}

.padding_full_11 {
  padding: 44px;
}

.padding_start_11 {
  padding-inline-start: 44px;
}

.padding_end_11 {
  padding-inline-end: 44px;
}

.padding_top_11 {
  padding-block-start: 44px;
}

.padding_bottom_11 {
  padding-block-end: 44px;
}

.padding_x_11 {
  padding-inline-end: 44px;
  padding-inline-start: 44px;
}

.padding_y_11 {
  padding-block-end: 44px;
  padding-block-start: 44px;
}

.border_radius_11 {
  border-radius: 44px;
  -o-border-radius: 44px;
  -moz-border-radius: 44px;
  -webkit-border-radius: 44px;
}

.margin_full_12 {
  margin: 48px;
}

.margin_start_12 {
  margin-inline-start: 48px;
}

.margin_end_12 {
  margin-inline-end: 48px;
}

.margin_top_12 {
  margin-block-start: 48px;
}

.margin_bottom_12 {
  margin-block-end: 48px;
}

.margin_x_12 {
  margin-inline-end: 48px;
  margin-inline-start: 48px;
}

.margin_y_12 {
  margin-block-end: 48px;
  margin-block-start: 48px;
}

.padding_full_12 {
  padding: 48px;
}

.padding_start_12 {
  padding-inline-start: 48px;
}

.padding_end_12 {
  padding-inline-end: 48px;
}

.padding_top_12 {
  padding-block-start: 48px;
}

.padding_bottom_12 {
  padding-block-end: 48px;
}

.padding_x_12 {
  padding-inline-end: 48px;
  padding-inline-start: 48px;
}

.padding_y_12 {
  padding-block-end: 48px;
  padding-block-start: 48px;
}

.border_radius_12 {
  border-radius: 48px;
  -o-border-radius: 48px;
  -moz-border-radius: 48px;
  -webkit-border-radius: 48px;
}

.icon_12 {
  width: 12px !important;
  height: 12px !important;
}

.font_12 {
  font-size: 12px !important;
}

.icon_16 {
  width: 16px !important;
  height: 16px !important;
}

.font_16 {
  font-size: 16px !important;
}

.icon_20 {
  width: 20px !important;
  height: 20px !important;
}

.font_20 {
  font-size: 20px !important;
}

.icon_24 {
  width: 24px !important;
  height: 24px !important;
}

.font_24 {
  font-size: 24px !important;
}

.icon_28 {
  width: 28px !important;
  height: 28px !important;
}

.font_28 {
  font-size: 28px !important;
}

.icon_32 {
  width: 32px !important;
  height: 32px !important;
}

.font_32 {
  font-size: 32px !important;
}

.icon_36 {
  width: 36px !important;
  height: 36px !important;
}

.font_36 {
  font-size: 36px !important;
}

.icon_40 {
  width: 40px !important;
  height: 40px !important;
}

.font_40 {
  font-size: 40px !important;
}

.icon_44 {
  width: 44px !important;
  height: 44px !important;
}

.font_44 {
  font-size: 44px !important;
}

.icon_48 {
  width: 48px !important;
  height: 48px !important;
}

.font_48 {
  font-size: 48px !important;
}

.icon_52 {
  width: 52px !important;
  height: 52px !important;
}

.font_52 {
  font-size: 52px !important;
}

.icon_56 {
  width: 56px !important;
  height: 56px !important;
}

.font_56 {
  font-size: 56px !important;
}

.font_14 {
  font-size: 14px !important;
}

.main_font_100 {
  font-weight: 100 !important;
  font-family: LoewNextArabic !important;
}

.main_font_300 {
  font-weight: 300 !important;
  font-family: LoewNextArabic !important;
}

.main_font_500 {
  font-weight: 500 !important;
  font-family: LoewNextArabic !important;
}

.main_font_700 {
  font-weight: 700 !important;
  font-family: LoewNextArabic !important;
}

.main_font_800 {
  font-weight: 800 !important;
  font-family: LoewNextArabic !important;
}

.main_font_900 {
  font-weight: 900 !important;
  font-family: LoewNextArabic !important;
}

.second_font_100 {
  font-weight: 100 !important;
  font-family: IBMPlexSansArabic !important;
}

.second_font_200 {
  font-weight: 200 !important;
  font-family: IBMPlexSansArabic !important;
}

.second_font_300 {
  font-weight: 300 !important;
  font-family: IBMPlexSansArabic !important;
}

.second_font_400 {
  font-weight: 400 !important;
  font-family: IBMPlexSansArabic !important;
}

.second_font_500 {
  font-weight: 500 !important;
  font-family: IBMPlexSansArabic !important;
}

.second_font_600 {
  font-weight: 600 !important;
  font-family: IBMPlexSansArabic !important;
}

.second_font_700 {
  font-weight: 700 !important;
  font-family: IBMPlexSansArabic !important;
}

.font_100 {
  font-weight: 100 !important;
}

.font_200 {
  font-weight: 200 !important;
}

.font_300 {
  font-weight: 300 !important;
}

.font_400 {
  font-weight: 400 !important;
}

.font_500 {
  font-weight: 500 !important;
}

.font_600 {
  font-weight: 600 !important;
}

.font_700 {
  font-weight: 700 !important;
}

.font_800 {
  font-weight: 800 !important;
}

.font_900 {
  font-weight: 900 !important;
}

.app_breadcrumb {
  padding: 25px 0;
  background-size: cover;
}
.app_breadcrumb ol {
  padding-inline-end: 30px;
}
.app_breadcrumb ol li:not(:first-of-type)::before {
  padding: 0 8px !important;
  content: url("/src/assets/img/arrow_top-right.svg") !important;
}
.app_breadcrumb ol li a {
  opacity: 0.8;
  color: #727580;
  font-family: LoewNextArabic;
}

.app_btn {
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  min-height: 48px;
  padding: 8px 35px;
  position: relative;
  font-family: LoewNextArabic;
  border: 1px solid currentColor;
}
.app_btn::before {
  top: 0;
  right: 0;
  left: 100%;
  z-index: -1;
  content: "";
  height: 100%;
  opacity: 15%;
  position: absolute;
  background-color: currentColor !important;
  transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.app_btn:hover {
  border-color: currentColor !important;
}
.app_btn:hover::before {
  left: 0px;
}
.app_btn svg {
  max-width: 40px;
  max-height: 15px;
}

.app_card {
  box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.04);
}
.app_card.full_details .wrap_bottom {
  margin-top: 0px !important;
}
.app_card .wrap_top .panner {
  width: 100%;
  height: 100%;
  min-height: 270px;
  max-height: 365px;
  object-fit: cover;
  border-radius: 100px 0;
}
.app_card .wrap_top .sharing {
  right: -159px;
  height: 45px;
  top: 20px;
  padding: 5px 15px 5px 15px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  transition: right 0.2s linear;
  -o-transition: right 0.2s linear;
  -moz-transition: right 0.2s linear;
  -webkit-transition: right 0.2s linear;
}
.app_card .wrap_top .sharing.show {
  right: 0px;
}
.app_card .wrap_top .sharing.show svg {
  width: 0px;
}
.app_card .wrap_top .sharing svg {
  fill: #007F5C;
  transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  -moz-transition: width 0.2s linear;
  -webkit-transition: width 0.2s linear;
}
.app_card .wrap_top .cost {
  left: 14px;
  bottom: 14px;
  width: calc(100% - 50px);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 80px 0;
  padding: 40px;
  backdrop-filter: blur(2px);
  -o-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.app_card .wrap_top .cost .amount span {
  color: #4D4E5F;
  font-family: LoewNextArabic;
}
.app_card .wrap_top .cost .amount span:first-of-type {
  font-size: 14px;
  font-weight: 300;
}
.app_card .wrap_top .cost .amount span:last-of-type {
  font-size: 14px;
  font-weight: 700;
}
.app_card .wrap_top .cost .product_progress {
  z-index: 1;
  height: 20px;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  background-color: rgba(114, 154, 168, 0.3);
  color: #FFFFFF;
  font-family: LoewNextArabic;
  border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.app_card .wrap_top .cost .product_progress .progress_status {
  top: 0;
  right: 0;
  bottom: 0;
  width: 10%;
  z-index: -1;
  background: #007F5C;
  border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.app_card .wrap_top .cost .product_progress .span {
  vertical-align: middle;
}
.app_card .wrap_mid > * {
  font-family: LoewNextArabic;
}
.app_card .wrap_mid h3 {
  font-size: 24px;
  font-weight: 700;
  color: #4D4E5F;
}
.app_card .wrap_mid h3 span {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -o-line-clamp: 1;
  -moz-line-clamp: 1;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  -o-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
}
.app_card .wrap_mid h3 span:first-of-type {
  line-height: normal;
}
.app_card .wrap_mid p {
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  color: #727580;
}
.app_card .wrap_bottom {
  margin-top: 30px;
}
.app_card .wrap_bottom .cates li.break_v_line {
  width: 1px;
  height: 14px;
  display: inline-block;
  background-color: #96979E;
}
.app_card .wrap_bottom .cates li span {
  font-weight: 500;
  color: #727580;
  font-family: LoewNextArabic;
}
.app_card .wrap_bottom .details li {
  width: calc(50% - 16px);
}
.app_card .wrap_bottom .wrap_input .wrap_pay {
  left: 0;
  top: 50%;
  z-index: 0;
  height: 100%;
  padding: 0 12px;
  background-color: #007F5C;
  border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
}
.app_card .wrap_bottom .wrap_input .wrap_pay span {
  color: #FFFFFF;
}
.app_card .wrap_bottom .wrap_input .wrap_pay svg path {
  fill: #FFFFFF;
}
.app_card .wrap_bottom .wrap_input .wrap_pay span, .app_card .wrap_bottom .wrap_input .wrap_pay svg {
  transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.app_card .wrap_bottom .wrap_input .wrap_pay:hover {
  background-color: #69B244;
}
.app_card .wrap_icon .heart_icon {
  border: 0.5px solid #007F5C;
}
.app_card .wrap_icon .share_icon {
  background-color: rgba(114, 117, 128, 0.15);
}

.carousel_panner {
  margin: 48px auto;
  overflow: hidden;
  border-radius: 100px 0;
}
.carousel_panner .carousel-indicators button {
  width: 15px;
  height: 15px;
  border: none !important;
  background-clip: unset !important;
  border-radius: 999px;
  -o-border-radius: 999px;
  -moz-border-radius: 999px;
  -webkit-border-radius: 999px;
  transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
}
.carousel_panner .carousel-indicators button.active {
  width: 40px;
  background-color: #007F5C;
}
.carousel_panner .carousel-item img {
  height: 267px;
  max-width: 100%;
  object-fit: cover;
}
.carousel_panner .carousel-item .carousel-caption {
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.carousel_panner .carousel-item .carousel-caption h1 {
  padding: 0 12px;
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  color: #f7f8f8;
  font-family: LoewNextArabic;
}

.app_checkbox .app_checkbox_input {
  border: 2px solid #727580;
}
.app_checkbox label {
  font-size: 14px;
  font-weight: 500;
  color: #4D4E5F;
  font-family: LoewNextArabic;
}

.pay_details {
  background-color: #FFFFFF;
}
.pay_details .types {
  text-align: center;
}
.pay_details .types li {
  cursor: pointer;
  padding: 15px 5px;
  border-bottom: 1px solid rgba(114, 117, 128, 0.5);
}
.pay_details .types li.active {
  border-radius: 10px 10px 0 0;
  color: #FFFFFF;
  border-color: #007F5C;
  background-color: #007F5C;
}
.pay_details .prices {
  gap: 10px;
}
.pay_details .prices li input:checked + label span {
  color: #FFFFFF;
}
.pay_details .prices li input:checked + label::after {
  scale: 1;
  border-radius: 0px;
  -o-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}
.pay_details .prices li label {
  z-index: 1;
}
.pay_details .prices li label::after {
  inset: 0;
  scale: 0;
  content: "";
  z-index: -1;
  position: absolute;
  background-color: #007F5C;
  border-radius: 9999px;
  -o-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.pay_details form .col-12:first-of-type .app_input_field input {
  border-color: rgba(114, 117, 128, 0.5);
}
.pay_details form .gift_donation_box {
  display: none;
}
.pay_details form select {
  width: 100%;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  appearance: none;
  min-height: 48px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16 10L12 14L8 10" stroke="%235D686F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat 10px center;
  color: #96979E;
  border: 1px solid rgba(114, 117, 128, 0.5);
}
.pay_details form select option {
  font-size: 14px;
  font-weight: 500;
  color: #96979E;
  padding: 17px 10px;
}
.pay_details form .payment_method {
  gap: 20px;
}
.pay_details form .payment_method li {
  cursor: pointer;
  min-width: 80px;
  padding: 6px 8px;
  border: 1px solid #E6E6E6;
}
.pay_details form .payment_method li.active {
  border-color: #007F5C;
}
.pay_details form .payment_method li svg {
  width: 37px;
  height: 37px;
}
.pay_details form .terms {
  margin-bottom: 22px;
}
.pay_details form .app_btn {
  padding: 18px 35px;
}

.drop_down_menu {
  padding: 10px;
  min-width: 160px;
  border-radius: 10px;
  display: inline-block;
  font-weight: 500;
  font-family: IBMPlexSansArabic;
  border: 1px solid #144A65;
}
.drop_down_menu .drop_down_name {
  color: #144A65;
}
.drop_down_menu ul {
  display: none;
}
.drop_down_menu ul.show {
  display: block;
}

.fast_donate {
  right: 0;
  top: 200px;
  width: 100%;
  z-index: 50;
  position: fixed;
  max-width: 160px;
  border-radius: 12px 0 0 12px;
  -o-border-radius: 12px 0 0 12px;
  -moz-border-radius: 12px 0 0 12px;
  -webkit-border-radius: 12px 0 0 12px;
  transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}
.fast_donate.collapsed {
  max-width: 60px;
}
.fast_donate.collapsed span {
  display: none;
}
.fast_donate.show svg:first-of-type {
  display: none !important;
}
.fast_donate.show svg:last-of-type {
  display: block !important;
}
.fast_donate:hover {
  color: #007F5C !important;
  background-color: #FFFFFF !important;
}
.fast_donate:hover svg, .fast_donate:hover svg circle {
  stroke: #007F5C;
}
.fast_donate span {
  white-space: nowrap;
}
.fast_donate svg {
  display: none;
}
.fast_donate svg:first-of-type {
  display: block;
}

header {
  overflow: hidden;
  height: 267px;
  background-color: rgba(0, 127, 92, 0.8);
  margin: 48px auto;
  border-radius: 100px 0;
  position: relative;
}
header h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  color: #f7f8f8;
  font-family: LoewNextArabic;
  position: absolute;
}

header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/src/assets/img/product_1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}

@media (max-width: 767px) {
  header::before {
    background-size: 100% 267px;
  }
}
.app_icon {
  z-index: 1;
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  -o-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
.app_icon::after {
  inset: 0;
  scale: 0;
  content: "";
  z-index: -1;
  position: absolute;
  background-color: currentColor;
  border-radius: 999px;
  -o-border-radius: 999px;
  -moz-border-radius: 999px;
  -webkit-border-radius: 999px;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.app_icon.square {
  border: 1px solid #FFFFFF;
}
.app_icon:hover::after {
  scale: 1;
  border-radius: 0px;
  -o-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.app_input_field.hasIcon .wrap_input input {
  padding-inline-start: 50px;
}
.app_input_field.is_valid .wrap_input .wrap_icon svg, .app_input_field.is_valid .wrap_input .wrap_password_icon svg {
  fill: #007F5C !important;
}
.app_input_field.is_valid .wrap_input input {
  color: #007F5C !important;
  border-color: #007F5C !important;
}
.app_input_field.is_invalid .wrap_input .wrap_icon svg, .app_input_field.is_invalid .wrap_input .wrap_password_icon svg {
  fill: var(--bs-danger) !important;
}
.app_input_field.is_invalid .wrap_input input {
  color: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
}
.app_input_field label {
  font-size: 14px;
  font-weight: 500;
  color: #4D4E5F;
  font-family: IBMPlexSansArabic;
}
.app_input_field label .required {
  color: #E3171A;
}
.app_input_field .wrap_input .wrap_icon {
  top: 50%;
  left: 0px;
  padding: 0 10px;
  transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.app_input_field .wrap_input .wrap_icon svg {
  fill: #667085;
}
.app_input_field .wrap_input .wrap_password_icon, .app_input_field .wrap_input .wrap_upload_icon {
  left: 0;
  width: 50px;
}
.app_input_field .wrap_input .wrap_password_icon.wrap_password_icon, .app_input_field .wrap_input .wrap_upload_icon.wrap_password_icon {
  cursor: pointer;
}
.app_input_field .wrap_input .wrap_password_icon.wrap_password_icon svg, .app_input_field .wrap_input .wrap_upload_icon.wrap_password_icon svg {
  fill: #4D4E5F;
}
.app_input_field .wrap_input .override_label_file {
  border: 0px;
  width: 100%;
  direction: rtl;
  font-size: 16px;
  min-height: 48px;
  font-weight: 500;
  padding: 12px 15px;
  font-family: LoewNextArabic;
  color: #96979E !important;
  border: 1px solid rgba(150, 151, 158, 0.5);
}
.app_input_field .wrap_input .override_label_file .wrap_file_icon svg {
  fill: #4D4E5F;
}
.app_input_field .wrap_input .override_label_file .file_placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #727580;
  font-family: IBMPlexSansArabic;
}
.app_input_field .wrap_input input {
  border: 0px;
  direction: rtl;
  font-size: 16px;
  min-height: 48px;
  font-weight: 500;
  padding: 12px 15px;
  font-family: LoewNextArabic;
  color: #96979E !important;
  border: 1px solid rgba(150, 151, 158, 0.5);
}
.app_input_field .wrap_input input::placeholder {
  font-size: 14px;
  color: #96979E;
}
.app_input_field .wrap_input input::file-selector-button {
  display: none;
}
.app_input_field .wrap_input input[type=date] {
  display: flex;
  text-align: end;
  flex-direction: row-reverse;
}
.app_input_field .wrap_input input[type=date]::-o-calendar-picker-indicator {
  background-image: url("/src/assets/img/fi_2669764.svg");
}
.app_input_field .wrap_input input[type=date]::-moz-calendar-picker-indicator {
  background-image: url("/src/assets/img/fi_2669764.svg");
}
.app_input_field .wrap_input input[type=date]::-ms-calendar-picker-indicator {
  background-image: url("/src/assets/img/fi_2669764.svg");
}
.app_input_field .wrap_input input[type=date]::-webkit-calendar-picker-indicator {
  background-image: url("/src/assets/img/fi_2669764.svg");
}
.app_input_field .wrap_input input:focus {
  box-shadow: none;
}
.app_input_field .wrap_input input[type=password] {
  padding-inline-end: 50px;
}
.app_input_field .invalid-feedback {
  opacity: 0.7;
  font-size: 14px;
  font-weight: 500;
  margin-block-start: 16px;
  font-family: LoewNextArabic;
}

.app_popup_pay {
  display: none !important;
  padding: 30px 12px;
  inset: 0;
  z-index: 500;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
}
.app_popup_pay.show {
  display: flex !important;
}
.app_popup_pay.animation .wrap_pay, .app_popup_pay.animation .pay_details {
  transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
}
.app_popup_pay .pay_details {
  width: 100%;
  max-width: 530px;
  display: none !important;
  transform: translate(0px, 500px);
  -o-transform: translate(0px, 500px);
  -moz-transform: translate(0px, 500px);
  -webkit-transform: translate(0px, 500px);
  transition: transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  -moz-transition: transform 0.1s linear;
  -webkit-transition: transform 0.1s linear;
}
.app_popup_pay .pay_details.show {
  display: block !important;
}
.app_popup_pay .wrap_pay {
  width: 100%;
  max-width: 533px;
  min-height: 650px;
  display: none !important;
  padding: 47px 27px 20px 27px;
  background-color: #FFFFFF;
  transform: translate(0px, 500px);
  -o-transform: translate(0px, 500px);
  -moz-transform: translate(0px, 500px);
  -webkit-transform: translate(0px, 500px);
  transition: transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  -moz-transition: transform 0.1s linear;
  -webkit-transition: transform 0.1s linear;
}
.app_popup_pay .wrap_pay.show {
  display: flex !important;
}
.app_popup_pay .wrap_pay .wrap_sides .logo {
  max-width: 189px;
}
.app_popup_pay .wrap_pay .wrap_sides .payment_method {
  gap: 28px;
}
.app_popup_pay .wrap_pay .wrap_sides form .app_btn {
  padding: 18px 35px;
}
.app_popup_pay .wrap_alert_msg {
  width: 100%;
  display: none;
  max-width: 456px;
  padding: 30px 40px;
  background-color: #FFFFFF;
  transform: translate(0px, 500px);
  -o-transform: translate(0px, 500px);
  -moz-transform: translate(0px, 500px);
  -webkit-transform: translate(0px, 500px);
  transition: transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  -moz-transition: transform 0.1s linear;
  -webkit-transition: transform 0.1s linear;
}
.app_popup_pay .wrap_alert_msg.animation {
  transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
}
.app_popup_pay .wrap_alert_msg.show {
  display: block;
}
.app_popup_pay .wrap_alert_msg .back_to_Home {
  padding: 18px 35px !important;
}

.app_popup_sharing {
  display: none !important;
  padding: 85px 12px;
  inset: 0;
  z-index: 500;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
}
.app_popup_sharing.show {
  display: flex !important;
}
.app_popup_sharing.animation .wrap_content {
  transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
}
.app_popup_sharing .wrap_content {
  width: 100%;
  max-width: 600px;
  background-color: #FFFFFF;
  transform: translate(0px, 500px);
  -o-transform: translate(0px, 500px);
  -moz-transform: translate(0px, 500px);
  -webkit-transform: translate(0px, 500px);
  transition: transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  -moz-transition: transform 0.1s linear;
  -webkit-transition: transform 0.1s linear;
}
.app_popup_sharing .wrap_content .wrap_top h4 {
  line-height: normal;
}
.app_popup_sharing .wrap_content .wrap_copy_url {
  width: 100%;
  padding: 10px;
  max-width: 390px;
  padding-inline-start: 44px;
  border: 1px solid rgba(114, 117, 128, 0.5);
}
.app_popup_sharing .wrap_content .wrap_copy_url .wrap_icon_copy {
  top: -1px;
  right: -1px;
  bottom: -1px;
  padding: 5px 10px;
  background-color: #007F5C;
  border-radius: 0 8px 8px 0;
  -o-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -webkit-border-radius: 0 8px 8px 0;
}
.app_popup_sharing .wrap_content .wrap_copy_url .copied {
  top: -1px;
  left: -1px;
  bottom: -1px;
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: calc(100% - 42px);
  border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
}
.app_popup_sharing .wrap_content ul {
  width: 100%;
  max-width: 250px;
  margin: 36px auto 5px auto;
}
.app_popup_sharing .wrap_content ul li:hover a {
  background-color: rgba(10, 110, 148, 0.12);
}
.app_popup_sharing .wrap_content ul li a {
  width: 48px;
  height: 48px;
  display: block;
  border-radius: 9999px;
  -o-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
  transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
}

.social_icons li, .share_icons li {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 15px 0;
  background-color: #007F5C;
  transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
}
.social_icons li a, .share_icons li a {
  height: 100%;
  flex-grow: 1;
}
.social_icons li:hover, .share_icons li:hover {
  background-color: #69B244;
}

.navbar {
  padding-top: 30px;
  padding-bottom: 25px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  -o-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
}
.navbar .wrap_navbar .lang {
  min-width: 86px;
  font-size: 14px;
  min-height: 56px;
  font-weight: 500;
  border-radius: 16px 0;
  text-decoration: none;
  color: #000000;
  background: rgba(0, 0, 0, 0.06);
}
.navbar .wrap_navbar .navbar-collapse {
  width: 100%;
  justify-content: center;
  padding-inline-end: 140px;
  padding-inline-start: 75px;
}
.navbar .wrap_navbar .navbar-collapse ul li a svg {
  stroke: #1A1A1A;
}
.navbar .wrap_navbar .navbar-collapse ul li a.active {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800 !important;
  color: #007F5C !important;
}
.navbar .wrap_navbar .navbar-collapse ul li a.active svg {
  stroke: #007F5C;
}
.navbar .wrap_navbar .options .account button {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  color: #69B244;
  font-family: IBMPlexSansArabic;
  border: 1px solid #69B244;
}
.navbar .wrap_navbar .options .account button.user {
  color: #FFFFFF;
  padding: 8px 35px;
  border-radius: 20px 0;
  background-color: #007F5C;
}
.navbar .wrap_navbar .options .account button:hover {
  background-color: #7EBF5C !important;
}
.navbar .wrap_navbar .options .backward {
  padding: 10px;
  vertical-align: unset;
  background: linear-gradient(264deg, #1F7F52 10.11%, #69B244 102.27%);
}

aside {
  display: none;
  inset: 0;
  z-index: 500;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
}
aside.show {
  display: block;
}
aside.animation .side_menu {
  transform: translate(0px, 0);
  -o-transform: translate(0px, 0);
  -moz-transform: translate(0px, 0);
  -webkit-transform: translate(0px, 0);
}
aside .side_menu {
  width: 100%;
  height: 100%;
  padding: 24px;
  max-width: 300px;
  background-color: #007F5C;
  transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  -moz-transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
  transition: transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  -moz-transition: transform 0.2s linear;
  -webkit-transition: transform 0.2s linear;
}
aside .side_menu .wrap_logo {
  margin: 0 auto;
  max-width: 240px;
}
aside .side_menu ul {
  overflow: auto;
  max-height: 80%;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.5) transparent rgba(255, 255, 255, 0.5) transparent;
}
aside .side_menu ul li {
  font-weight: 500;
  font-family: LoewNextArabic;
}
aside .side_menu ul li a {
  color: #FFFFFF;
  transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -webkit-transition: color 0.3s linear;
}
aside .side_menu ul li:hover a, aside .side_menu ul li.active a {
  color: #69B244;
}
aside .side_menu .lang {
  min-width: 86px;
  font-size: 14px;
  min-height: 46px;
  font-weight: 500;
  border-radius: 16px 0;
  text-decoration: none;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.06);
}

.acc_side_menu {
  min-height: 921px;
}
.acc_side_menu ul li {
  min-height: 56px;
}
.acc_side_menu ul li.active a {
  font-weight: 600 !important;
  color: #007F5C;
  border: 1px solid #007F5C;
  background-color: #EDFFF7;
}
.acc_side_menu ul li.active a svg {
  fill: #007F5C;
}
.acc_side_menu ul li a {
  background-color: rgba(193, 193, 193, 0.2);
}
.acc_side_menu ul li a:hover {
  color: #007F5C;
}
.acc_side_menu ul li a div {
  min-width: 135px;
  line-height: normal;
  vertical-align: middle;
}
.acc_side_menu ul li a div svg {
  color: #4D4E5F;
}
.acc_side_menu .logout {
  color: #C54C44;
  background-color: #FFEEED;
}

.account_statistics .wrap_statistics .wrap_img {
  width: 100%;
  max-width: 184px;
}
.account_statistics .wrap_statistics .wrap_img::after {
  inset: 0;
  z-index: 1;
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(31, 127, 82, 0.7490196078) 0%, rgba(255, 255, 255, 0) 100%);
}
.account_statistics .wrap_statistics .wrap_details ul li {
  width: 50%;
}
.account_statistics .wrap_statistics .wrap_details .progress_wrap {
  height: 16px;
  padding: 0px 2px;
  background-color: #D9D9D9;
  border-radius: 3px;
  -o-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.account_statistics .wrap_statistics .wrap_details .progress_wrap .progress_bar {
  width: 75%;
  height: 12px;
  background-color: #007F5C;
  border-radius: 3px;
  -o-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.account_donations .counters {
  width: 100%;
  max-width: 405px;
}
.account_donations .counters .wrap_img {
  width: 56px;
  height: 56px;
  background-color: #F2F9EC;
  border-radius: 9999px;
  -o-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
}
.account_donations table {
  border-spacing: 0;
  border-collapse: separate;
}
.account_donations table thead, .account_donations table tbody {
  width: 100%;
}
.account_donations table thead th {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 18px 26px;
  border-bottom-width: 0px;
  background: rgba(193, 193, 193, 0.2);
  color: #063046;
  font-family: IBMPlexSansArabic;
}
.account_donations table thead th:first-of-type {
  border-radius: 0px 8px 8px 0px;
  -o-border-radius: 0px 8px 8px 0px;
  -moz-border-radius: 0px 8px 8px 0px;
  -webkit-border-radius: 0px 8px 8px 0px;
}
.account_donations table thead th:last-of-type {
  border-radius: 8px 0 0px 8px;
  -o-border-radius: 8px 0 0px 8px;
  -moz-border-radius: 8px 0 0px 8px;
  -webkit-border-radius: 8px 0 0px 8px;
}
.account_donations table tbody {
  margin-top: 8px; /* Adjust the margin-bottom as needed */
}
.account_donations table tbody tr:first-of-type th, .account_donations table tbody tr:first-of-type td {
  border-top: none !important;
}
.account_donations table tbody th, .account_donations table tbody td {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 24px 26px;
  border: 1px solid #EDEDED;
  color: #727580;
  font-family: IBMPlexSansArabic;
}
.account_donations table tbody th:first-of-type, .account_donations table tbody td:first-of-type {
  border-right-width: 0px;
}
.account_donations table tbody th:last-of-type, .account_donations table tbody td:last-of-type {
  border-left-width: 0px;
}

.my_account form .wrap_gender .wrap_radio {
  width: 50%;
}
.my_account form .wrap_gender .wrap_radio:first-of-type {
  margin-inline-end: 24px;
}
.my_account form .wrap_gender .wrap_radio input:checked + label {
  color: #007F5C;
  border-color: currentColor;
}
.my_account form .wrap_gender .wrap_radio input:checked + label span {
  color: currentColor;
}
.my_account form .wrap_gender .wrap_radio input:checked + label span.wrap_checked {
  border-color: currentColor;
}
.my_account form .wrap_gender .wrap_radio input:checked + label span.wrap_checked .checked {
  display: inline-block;
}
.my_account form .wrap_gender .wrap_radio label {
  max-height: 48px;
  padding: 16px 13px;
  color: #727580;
  border: 1px solid currentColor;
}
.my_account form .wrap_gender .wrap_radio label .wrap_checked {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  border: 3px solid currentColor;
  border-radius: 9999px;
  -o-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
}
.my_account form .wrap_gender .wrap_radio label .wrap_checked .checked {
  top: 50%;
  left: 50%;
  width: 11.5px;
  height: 11.5px;
  display: none;
  position: absolute;
  background-color: #007F5C;
  border-radius: 100px;
  -o-border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.my_account form .wrap_checkBox .app_checkbox:first-of-type {
  margin-bottom: 20px;
}
.my_account form .wrap_checkBox .app_checkbox input {
  width: 24px;
  height: 24px;
  margin-inline-end: 9px;
  border-color: #727580;
  background-color: #727580;
}
.my_account form .wrap_checkBox .app_checkbox input:checked {
  border-color: #007F5C;
  background-color: #007F5C;
}
.my_account form .wrap_checkBox .app_checkbox input:checked + label {
  color: #007F5C;
  font-weight: 500 !important;
}
.my_account form .wrap_checkBox .app_checkbox label {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #727580;
  font-family: IBMPlexSansArabic !important;
}
.my_account form .submit .app_btn {
  width: 100%;
  max-width: 296px;
}

.auth_pages {
  padding: 40px 0;
  min-height: 100vh;
  background: url("/src/assets/img/auth_bg.png") top left no-repeat;
  background-size: 35% 100%;
}
.auth_pages.registration .hr {
  margin: 24px auto !important;
}
.auth_pages .logo {
  width: 100%;
  max-width: 204px;
}
.auth_pages form .app_input_field.email svg {
  stroke: #667085;
  fill: none !important;
}
.auth_pages form .app_input_field .wrap_input .wrap_icon {
  right: 0px;
  left: auto !important;
}
.auth_pages form .app_input_field .wrap_input input {
  min-height: 62px;
  border: 1px solid #D0D5DD;
}
.auth_pages form .app_input_field .wrap_input input::placeholder {
  color: rgba(93, 104, 111, 0.5);
  font-size: 16px !important;
}
.auth_pages form .app_checkbox_input {
  border-radius: 2px;
  border-color: #C4C4C4;
  background-color: #C4C4C4;
}
.auth_pages form .submit {
  margin-top: 73px;
}
.auth_pages form .submit .login {
  min-height: 56px;
}
.auth_pages form .submit .register {
  min-height: 62px;
}
.auth_pages form .submit .hr {
  height: 1px;
  width: 185px;
  margin: 56px auto;
  background-color: rgba(114, 117, 128, 0.3);
}

.home_page {
  background-color: #F7F8F8;
}
.home_page .home_page_card input {
  padding-inline-end: 130px;
}

.notifications .wrap_tabs {
  margin-top: 55px;
}
.notifications .wrap_tabs .tabs li {
  padding: 11px;
  color: #6F7481;
  max-width: 187px;
  width: 100%;
  min-height: 46px;
  border: 0.3px solid #7E8598;
  background-color: rgba(232, 235, 237, 0.5);
}
.notifications .wrap_tabs .tabs li.active {
  color: #007F5C;
  border-color: #007F5C;
  background-color: #FFFFFF;
}
.notifications .dates li {
  border-radius: 18px;
  -o-border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.05);
}
.notifications .dates li .wrap_img {
  width: 50px;
  height: 50px;
  background-color: #F2F9EC;
  border-radius: 9999px;
  -o-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
}
.notifications .dates li .wrap_right p {
  vertical-align: super;
}
.notifications .dates li .wrap_left .status {
  color: #6F7481;
  min-height: 44px;
  padding: 10px 25px;
  background-color: #E8EBED;
  border-radius: 22px;
  -o-border-radius: 22px;
  -moz-border-radius: 22px;
  -webkit-border-radius: 22px;
}
.notifications .dates li .wrap_left .status.unread {
  color: #045C50;
  background-color: #F2F9EC;
}

.product_details .wrap_page {
  margin-top: 72px;
}

.register_non_profit_org header {
  min-height: 81px;
  margin-block-end: 20px;
  background-size: cover;
}
.register_non_profit_org header + h4 {
  font-size: 18px;
}
.register_non_profit_org .steppers ul {
  overflow: hidden;
}
.register_non_profit_org .steppers ul li {
  position: relative;
  float: inline-start;
  width: 25%;
}
.register_non_profit_org .steppers ul li:first-of-type:first-of-type, .register_non_profit_org .steppers ul li:last-of-type:first-of-type {
  text-align: start;
}
.register_non_profit_org .steppers ul li:first-of-type:first-of-type div, .register_non_profit_org .steppers ul li:last-of-type:first-of-type div {
  position: relative;
}
.register_non_profit_org .steppers ul li:first-of-type:first-of-type div::after, .register_non_profit_org .steppers ul li:last-of-type:first-of-type div::after {
  top: 0;
  right: 0;
  width: 50%;
  z-index: -1;
  height: 50%;
  content: "";
  position: absolute;
  background-color: #FFFFFF;
}
.register_non_profit_org .steppers ul li:first-of-type:last-of-type, .register_non_profit_org .steppers ul li:last-of-type:last-of-type {
  text-align: end;
}
.register_non_profit_org .steppers ul li:first-of-type:last-of-type div, .register_non_profit_org .steppers ul li:last-of-type:last-of-type div {
  position: relative;
}
.register_non_profit_org .steppers ul li:first-of-type:last-of-type div::before, .register_non_profit_org .steppers ul li:last-of-type:last-of-type div::before {
  top: 0;
  left: 0;
  width: 50%;
  z-index: -1;
  height: 50%;
  content: "";
  position: absolute;
  background-color: #FFFFFF;
}
.register_non_profit_org .steppers ul li::after, .register_non_profit_org .steppers ul li::before {
  top: 16px;
  z-index: -2;
  height: 1px;
  width: 50%;
  content: "";
  position: absolute;
  background-color: rgba(150, 151, 158, 0.5);
}
.register_non_profit_org .steppers ul li::before {
  left: 50%;
}
.register_non_profit_org .steppers ul li::after {
  left: 0%;
}
.register_non_profit_org .steppers ul li:last-of-type.active::after {
  background-color: #007F5C;
}
.register_non_profit_org .steppers ul li.active.completed + ::before, .register_non_profit_org .steppers ul li.active.completed::after, .register_non_profit_org .steppers ul li.active.completed::before, .register_non_profit_org .steppers ul li.completed.completed + ::before, .register_non_profit_org .steppers ul li.completed.completed::after, .register_non_profit_org .steppers ul li.completed.completed::before {
  background-color: #007F5C;
}
.register_non_profit_org .steppers ul li.active.completed div .step_title, .register_non_profit_org .steppers ul li.completed.completed div .step_title {
  color: #007F5C;
}
.register_non_profit_org .steppers ul li.active div span.step_number, .register_non_profit_org .steppers ul li.completed div span.step_number {
  color: #FFFFFF;
  background-color: #007F5C;
}
.register_non_profit_org .steppers ul li span {
  margin: 0 auto;
  display: block;
}
.register_non_profit_org .steppers ul li span.step_number {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-bottom: 16px;
  vertical-align: middle;
  background-color: #D9D9D9;
  color: #727580;
  border-radius: 9999px;
  -o-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
}
.register_non_profit_org form .wrap_step {
  min-height: 690px;
}
.register_non_profit_org form .wrap_step.show {
  display: flex !important;
}
.register_non_profit_org form .app_input_field .wrap_input .wrap_icon {
  right: 0;
  left: auto !important;
}
.register_non_profit_org form .app_input_field .wrap_input input {
  padding-inline-start: 32px;
}
.register_non_profit_org form .steppers_buttons .btn {
  width: 100%;
  max-width: 295px;
  min-height: 56px;
}
.register_non_profit_org .complete {
  display: none !important;
  inset: 0;
  z-index: 500;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
}
.register_non_profit_org .complete.show {
  display: flex !important;
}
.register_non_profit_org .complete.animation .wrap_alert_complete {
  transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
}
.register_non_profit_org .complete p {
  line-height: 24px;
}
.register_non_profit_org .complete .wrap_alert_complete {
  width: 100%;
  max-width: 512px;
  background-color: #FFFFFF;
  transform: translate(0px, 500px);
  -o-transform: translate(0px, 500px);
  -moz-transform: translate(0px, 500px);
  -webkit-transform: translate(0px, 500px);
  transition: transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  -moz-transition: transform 0.1s linear;
  -webkit-transition: transform 0.1s linear;
}
.register_non_profit_org .complete .wrap_alert_complete .app_btn {
  width: 100%;
  min-height: 56px;
  margin-top: 61px;
  max-width: 296px;
}

.account .wrap_content {
  padding: 32px 0 55px 0;
}

.terms_privacy_policy h1 {
  line-height: 90px;
  padding: 100px 10px;
  background-color: #FBFBFB;
  background: url("/src/assets/img/shape.svg") 0px bottom no-repeat;
}
.terms_privacy_policy .wrap_content {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}
.terms_privacy_policy .wrap_content p {
  line-height: 35px;
}

footer {
  margin-top: 85px;
  box-shadow: 0px 8px 10px 10px rgba(0, 0, 0, 0.06);
  -o-box-shadow: 0px 8px 10px 10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 8px 10px 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 8px 10px 10px rgba(0, 0, 0, 0.06);
}
footer .description {
  max-width: 270px;
  line-height: 35px;
}
footer h3 {
  font-size: 16px;
  font-weight: 700;
  color: #1A1A1A;
}
footer .links li:hover a {
  color: #007F5C;
}
footer .links li a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #1A1A1A;
  transition: 0.1s linear;
  -o-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
}
footer .bt_footer {
  border-top: 1px solid rgba(150, 151, 158, 0.2);
}
footer .bt_footer .scroll_to_top {
  width: 47px;
  height: 47px;
  border-radius: 16px 0;
  background-color: #69B244;
}

.loading_screen {
  inset: 0;
  z-index: 1000;
  padding: 12px;
  position: fixed;
  background-color: #FFFFFF;
  transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
}
.loading_screen.hide {
  opacity: 0;
}
.loading_screen .wrap_logo {
  width: 100%;
  max-width: 350px;
  text-align: center;
  animation: scaling_animate 1.1s linear infinite forwards;
}

@keyframes scaling_animate {
  0% {
    scale: 0.8;
  }
  50% {
    scale: 1.5;
  }
  100% {
    scale: 0.8;
  }
}
@media (max-width: 576px) {
  .product_details .pay_details h4 {
    font-weight: 700;
    line-height: 22px;
    margin-block-end: 16px;
    font-size: 14px !important;
  }
  .product_details .pay_details .prices li label {
    padding: 13px !important;
    font-size: 14px !important;
  }
  .product_details .pay_details form .app_checkbox label {
    font-size: 12px !important;
  }
  .product_details .sponsor .wrap_img {
    min-height: 85px;
    max-width: 112px !important;
    padding: 16px 6px !important;
    margin-inline-end: 12px !important;
  }
  .product_details .sponsor h4 {
    font-size: 12px !important;
  }
  .account_statistics .wrap_statistics .wrap_img {
    margin-block-end: 10px;
    margin-inline-end: 0px !important;
  }
  .terms_privacy_policy h1 {
    line-height: 60px;
    padding-bottom: 120px;
    font-size: 20px !important;
  }
}
@media (max-width: 991px) {
  .account .acc_side_menu {
    margin-bottom: 32px;
    min-height: 500px;
  }
  .notifications .tabs li {
    margin-inline-end: 0px !important;
  }
  .notifications .dates li .wrap_right {
    margin-bottom: 10px;
  }
  .auth_pages {
    background: none !important;
  }
  .auth_pages .wrap_form {
    padding: 16px;
    background-color: #FFFFFF;
    border-radius: 12px;
    -o-border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
    -o-box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
    -webkit-box-shadow: 2px 10px 20px 15px rgba(0, 0, 0, 0.04);
  }
  .auth_pages .panner {
    margin-bottom: -70%;
  }
  .auth_pages .panner .wrap_panner {
    overflow: hidden;
    border-radius: 15px;
    -o-border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
  }
  .auth_pages .panner .wrap_panner img {
    top: -60px;
    z-index: -1;
    position: relative;
    filter: blur(1px);
  }
}
@media (max-width: 767px) {
  .account_donations .counters {
    max-width: none !important;
  }
  .register_non_profit_org header {
    background-size: auto;
  }
  .register_non_profit_org .steppers ul li.completed .step_title {
    display: none !important;
  }
  .register_non_profit_org .steppers ul li.active .step_title {
    display: block !important;
    font-size: 12px !important;
    color: #007F5C;
  }
  .register_non_profit_org .steppers_buttons .btn {
    max-width: 100% !important;
  }
  .register_non_profit_org .steppers_buttons .btn:first-of-type {
    margin-block-end: 16px;
    margin-inline-end: 0px;
  }
}
@media (max-width: 576px) {
  .app_btn {
    font-size: 12px;
    padding: 10px 11px;
  }
  .app_card {
    padding: 8px;
  }
  .app_card .wrap_top .cost {
    line-height: normal;
    padding: 25px 40px !important;
  }
  .app_card .wrap_top .cost .amount span {
    font-size: 12px !important;
  }
  .app_card .wrap_mid {
    margin-block-start: 10px;
  }
  .app_card .wrap_mid h3 {
    line-height: 22px; /* 157.143% */
  }
  .app_card .wrap_mid h3 span:first-of-type {
    font-size: 16px !important;
  }
  .app_card .wrap_mid h3 span:last-of-type {
    font-size: 14px !important;
  }
  .app_card .wrap_mid p {
    font-size: 12px;
  }
  .app_card .wrap_bottom {
    margin-block-start: 28px;
  }
  .app_card .wrap_bottom .cates li {
    font-size: 12px !important;
  }
  .app_card .wrap_bottom .cates li img {
    width: 16px;
    height: 16px;
  }
  .app_card .wrap_bottom .more_details {
    margin-block-start: 12px;
  }
  .app_card .wrap_bottom .wrap_input input {
    font-size: 14px;
  }
  .app_card .wrap_bottom .wrap_input .wrap_pay span {
    font-size: 12px !important;
  }
  .navbar .logo {
    max-width: 110px;
  }
  .navbar .options .account button {
    margin-inline-end: 10px !important;
  }
  header h1, .header_panner h1, .carousel_panner h1 {
    font-weight: 500;
    line-height: 30px;
    font-size: 24px !important;
  }
  .app_popup_pay.show {
    display: block !important;
  }
  .app_popup_pay.hasAlertMsg {
    padding-top: 110px;
  }
  .app_popup_pay .pay_details .types li {
    font-size: 12px !important;
  }
  .app_popup_pay .pay_details #fastDonateTypeDes {
    font-size: 12px !important;
  }
  .app_popup_pay .pay_details .prices li label {
    font-size: 12px !important;
    padding: 10px !important;
  }
  .app_popup_pay .pay_details form .app_input_field input {
    font-size: 12px !important;
  }
  .app_popup_pay .pay_details form .app_input_field input::placeholder {
    font-size: 12px !important;
  }
  .app_popup_pay .pay_details form .payment_method img {
    width: 32px;
  }
  .app_popup_pay .pay_details form .wrap_btnn .app_btn {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .app_popup_pay .pay_details form .wrap_btnn .app_btn.apple_pay_btn img {
    margin: 0 !important;
  }
  .app_popup_pay .pay_details form .wrap_btnn .app_btn.apple_pay_btn span {
    display: none !important;
  }
}
@media (min-width: 768px) {
  aside {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .app_card .wrap_bottom .details li {
    font-size: 12px !important;
    padding: 12px 5px !important;
  }
  .app_card .wrap_bottom .details li span:last-of-type {
    font-size: 14px !important;
  }
  .app_popup_pay {
    overflow-y: auto;
  }
  .app_popup_pay .wrap_pay {
    min-height: auto !important;
    padding: 25px !important;
  }
  .app_popup_pay .wrap_pay .wrap_sides {
    width: 100% !important;
  }
  .app_popup_pay .wrap_pay .wrap_sides h4 {
    margin-block-end: 5px !important;
    font-size: 14px !important;
  }
  .app_popup_pay .wrap_pay .wrap_sides h4 + span {
    font-size: 12px !important;
  }
  .fast_donate {
    top: 410px !important;
  }
  .navbar .logo {
    max-width: 141px;
  }
  .navbar .options .account button {
    line-height: normal;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    padding: 10px !important;
    border-radius: 8px !important;
    background-color: #FFFFFF !important;
  }
  .navbar .options .account button svg path {
    fill: #007F5C;
  }
  .navbar .options .account button img {
    width: 20px;
    height: 20px;
    margin-inline-end: 0px !important;
  }
}
@media (min-width: 992px) {
  .app_card.full_details .wrap_top .panner {
    height: 400px !important;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .app_card .wrap_top .cost .amount {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }
  .app_card .wrap_bottom .details li {
    text-align: center;
    width: 100% !important;
    margin-inline-end: 0px !important;
  }
}
@media (min-width: 1200px) {
  .app_card.smallCard .more_details .wrap_icon.wrap_icon, .app_card.smallCard .more_details .more_details_btn.wrap_icon {
    align-items: center;
    justify-content: center;
  }
  .app_card.smallCard .more_details .wrap_icon.more_details_btn, .app_card.smallCard .more_details .more_details_btn.more_details_btn {
    margin-inline-end: 5px !important;
  }
}
@media (max-width: 576px) {
  .app_card_v1 {
    padding: 8px !important;
  }
}
/* // import globle types & style fonts */
@font-face {
  font-family: "LoewNextArabic";
  src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Thin.otf") format("opentype");
  font-weight: 100; /* Thin */
}
@font-face {
  font-family: "LoewNextArabic";
  src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Light.otf") format("opentype");
  font-weight: 300; /* Light */
}
@font-face {
  font-family: "LoewNextArabic";
  src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Medium.otf") format("opentype");
  font-weight: 500; /* Medium */
}
@font-face {
  font-family: "LoewNextArabic";
  src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Bold.otf") format("opentype");
  font-weight: 700; /* Bold */
}
@font-face {
  font-family: "LoewNextArabic";
  src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-ExtraBold.otf") format("opentype");
  font-weight: 800; /* Extra Bold */
}
@font-face {
  font-family: "LoewNextArabic";
  src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Heavy.otf") format("opentype");
  font-weight: 900; /* Heavy */
}
/* // */
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Thin.ttf") format("opentype");
  font-weight: 100; /* Thin */
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-ExtraLight.ttf") format("opentype");
  font-weight: 200; /* Thin */
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Light.ttf") format("opentype");
  font-weight: 300; /* Light */
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Medium.ttf") format("opentype");
  font-weight: 500; /* Medium */
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-SemiBold.ttf") format("opentype");
  font-weight: 600; /* SemiBold */
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Regular.ttf") format("opentype");
  font-weight: normal; /* Regular */
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Bold.ttf") format("opentype");
  font-weight: bold; /* Bold */
}
.close_icon {
  cursor: pointer;
  border-radius: 999px;
  margin: 0 auto 15px auto;
  stroke: #FFFFFF;
  border: 2px solid #FFFFFF;
  transition: transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  -moz-transition: transform 0.1s linear;
  -webkit-transition: transform 0.1s linear;
}
.close_icon:hover {
  stroke: #007F5C;
  border-color: #007F5C;
}