header {
  overflow: hidden;
  height: 267px;
  background-color: rgb(0, 127, 92, 0.8);
  margin: 48px auto;
  border-radius: 100px 0;
  position: relative;
  h1 {
    font-size: 48px;
    // margin-top: 70px;
    font-weight: 700;
    line-height: 55px;
    color: #f7f8f8;
    // vars
    font-family: $mainFont;
    position: absolute;
  }
}

header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/src/assets/img/product_1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}

@media (max-width: 767px) {
  header::before {
    background-size: 100% 267px;
  }
}
