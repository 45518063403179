.product_details {
    .wrap_page {
        margin-top: 72px;
    }
    // .sponsor {
    //     .wrap_img {
    //         width: 100%;
    //         max-width: 178px;
    //         min-height: 110px;
    //         padding: 14px 20px;
    //         img {
    //             width: 100%;
    //             max-width: 135px;
    //         }
    //     }
    //     .sponsor_details {
    //         .wrap:not(:last-of-type) {
    //             margin-block-end: 22px;
    //         }
    //         h5 {
    //             opacity: 0.8;
    //         }
    //     }
    // }
}