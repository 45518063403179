/* // import globle types & style fonts */
@font-face {
    font-family: 'LoewNextArabic';
    src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Thin.otf") format('opentype');
    font-weight: 100; /* Thin */
}

@font-face {
    font-family: 'LoewNextArabic';
    src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Light.otf") format('opentype');
    font-weight: 300; /* Light */
}

@font-face {
    font-family: 'LoewNextArabic';
    src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Medium.otf") format('opentype');
    font-weight: 500; /* Medium */
}

@font-face {
    font-family: 'LoewNextArabic';
    src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Bold.otf") format('opentype');
    font-weight: 700; /* Bold */
}

@font-face {
    font-family: 'LoewNextArabic';
    src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-ExtraBold.otf") format('opentype');
    font-weight: 800; /* Extra Bold */
}

@font-face {
    font-family: 'LoewNextArabic';
    src: url("/src/assets/fonts/LoewNextArabic/ArbFONTS-Loew-Next-Arabic-Heavy.otf") format('opentype');
    font-weight: 900; /* Heavy */
}


/* // */
@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Thin.ttf") format('opentype');
    font-weight: 100; /* Thin */
}

@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-ExtraLight.ttf") format('opentype');
    font-weight: 200; /* Thin */
}

@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Light.ttf") format('opentype');
    font-weight: 300; /* Light */
}

@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Medium.ttf") format('opentype');
    font-weight: 500; /* Medium */
}

@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-SemiBold.ttf") format('opentype');
    font-weight: 600; /* SemiBold */
}

@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Regular.ttf") format('opentype');
    font-weight: normal; /* Regular */
}

@font-face {
    font-family: 'IBMPlexSansArabic';
    src: url("/src/assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Bold.ttf") format('opentype');
    font-weight: bold; /* Bold */
}