.acc_side_menu {
    min-height: 921px;
    ul {
        li {
            min-height: 56px;
            &.active {
                a {
                    font-weight: 600 !important; 
                    // vars
                    color: $mainColor;
                    border: 1px solid $mainColor;
                    background-color: #EDFFF7;
                    svg {
                        fill: $mainColor;
                    }
                }
            }
            a {
                background-color: rgba(#C1C1C1, 20%);
                &:hover {
                    color: $mainColor;
                }
                div {
                    min-width: 135px;
                    line-height: normal;
                    vertical-align: middle;
                    svg {
                        color: $writing;
                    }
                }
            }
        }
    }
    .logout {
        color: #C54C44;
        background-color: #FFEEED;;
    }
}