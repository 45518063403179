.notifications {
    .wrap_tabs {
        margin-top: 55px;
        .tabs {
            li {
                padding: 11px;
                color: #6F7481;
                max-width: 187px;
                width: 100%;
                min-height: 46px;
                border: 0.3px solid #7E8598;
                background-color: rgba(#E8EBED, 50%);
                &.active {
                    color: $mainColor;
                    border-color: $mainColor;
                    background-color: $white;
                }
            }
        }
    }
    .dates {
        li {
            // mixin
            @include prefix(border-radius, 18px);
            @include prefix(box-shadow, 2px 0px 20px 2px rgba($black, 5%));
            .wrap_img {
                width: 50px;
                height: 50px;
                background-color: $green_50;
                @include prefix(border-radius, 9999px)
            }
            .wrap_right {
                p {
                    vertical-align: super;
                }
            }
            .wrap_left {
                .status {
                    color: #6F7481;
                    min-height: 44px;
                    padding: 10px 25px;
                    background-color: #E8EBED;
                    @include prefix(border-radius, 22px);
                    &.unread {
                        color: #045C50;
                        background-color: $green_50;

                    }
                }
            }
        }
    }
}